import { onMounted, ref } from "vue"
import { resetInputValue, cprFormatting, fileUploaded } from "../../foundation/js/form-methods"

export default {
  name: "TheForms",
  setup() {
    onMounted(() => {
      const allInputGroups = document.querySelectorAll(".nyk-js--form")
      allInputGroups.forEach((group) => {
        const inputs = group.querySelectorAll("input")
        inputs.forEach((input) => setDisabledClass(input, group))
        const select = group.querySelector("select")
        setDisabledClass(select, group)
      })
    })

    const setDisabledClass = (target, group) => {
      if (target && target.disabled) {
        if (target.classList.value.includes("nyk-form-choice__content__item__choice__input")) {
          const inputWrapper = target.closest(".nyk-js--choice-wrapper")
          inputWrapper?.classList.add("nyk-form-choice__content__item--disabled") // set class for disabled choice
        } else {
          group.classList.add("nyk-form--disabled") // set class for disabled input
        }
      }
    }

    const countCharacters = (e) => {
      const textarea = e.target
      const maxLength = textarea.getAttribute("maxlength")
      const currentLength = textarea.value.length
      const wrapper = e.target.closest(".nyk-js--form")
      const counter = wrapper.querySelector(".nyk-js--characters-count")
      if (counter) {
        counter.innerText = `${currentLength}/${maxLength}`
      }
    }

    return {
      resetInputValue,
      cprFormatting,
      fileUploaded,
      countCharacters,
    }
  },
}
