<script>
    import options from "./SendCalculationStepInfo.options"
    export default options
</script>

<template>
    <article class="nyk-send-calculation">
        <div class="nyk-send-calculation__content">
            <div class="nyk-send-calculation__content__info">
                <p class="nyk-send-calculation__content__info__description">
                    <strong class="nyk-send-calculation__content__info__description__label">
                        {{ currentStepTexts.boldPrefixText }}
                    </strong>
                </p>
                <p class="nyk-send-calculation__content__info__description__text">
                    {{ currentStepTexts.mainText }}
                </p>
            </div>

            <button v-on:click="nextStepCallback([])" class="nyk-send-calculation__content__link nyk-button nyk-button--filled-primary-dark">
                {{ currentStepTexts.buttonText }}
            </button>
        </div>
    </article>
</template>