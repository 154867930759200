import { createApp } from "vue"
import FindCenterContactPersons from "./FindCenterContactPersons"

const el = ".nyk-component--find-center-contact-persons"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((FindCenterContactPersonsDOM) => {
      const props = {
        apiUrl: FindCenterContactPersonsDOM.dataset.apiUrl,
        centerInformation: FindCenterContactPersonsDOM.dataset.centerInformation
          ? JSON.parse(FindCenterContactPersonsDOM.dataset.centerInformation)
          : null,
        defaultImage: FindCenterContactPersonsDOM.dataset.defaultImage,
        contactUrl: FindCenterContactPersonsDOM.dataset.contactUrl ?? null,
      }

      const component = createApp(FindCenterContactPersons, props).use(Store)
      component.mount(FindCenterContactPersonsDOM)
    })
  }
}
