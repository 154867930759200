function initGooglePlacesAutocomplete(inputfield, callback) {
  // we need to wait for google js file to initialize
  if (!window.google) {
    // if not ready, try again in x milliseconds
    setTimeout(initGooglePlacesAutocomplete.bind(this, inputfield, callback), 1000)
    return false
  }

  const places = new window.google.maps.places.Autocomplete(inputfield)
  window.google.maps.event.addListener(places, "place_changed", callback.bind(null, places))

  return true
}

export default initGooglePlacesAutocomplete
