import {createApp} from 'vue'
import options from './FooterContact.options'

const el = '.nyk-component--footer-contact'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    DOM.forEach((FooterContactDOM) => {
        const props = {
            // Add the correct search url
            searchUrl: FooterContactDOM.dataset.searchUrl
        }

        const template = FooterContactDOM.outerHTML
        options.template = template
        const component = createApp(options, props).use(Store)
        component.mount(FooterContactDOM)
    })
}

