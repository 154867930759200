import { ref, onMounted } from "vue"
import _signupStepsCommonProps from "../../foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../../../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepActions/SignupStepActions.vue"
import Tooltip from "../Tooltip/Tooltip.vue"
import { cprFormatting } from "../../foundation/js/form-methods.js"

export default {
  name: "ContactStepOnboardingContent",
  props: _signupStepsCommonProps,
  components: {
    SignupStepActions,
    Tooltip,
  },
  setup(props) {
    const firstNameInput = ref(props.getNamedFieldInputData.firstname)
    const lastNameInput = ref(props.getNamedFieldInputData.lastname)
    const emailInput = ref(props.getNamedFieldInputData.email)
    const phoneInput = ref(props.getNamedFieldInputData.phone)
    const homeOwnerInput = ref(props.getNamedFieldInputData.homeOwner)
    const urgentAccountInput = ref(props.getNamedFieldInputData.urgentAccount)
    const contentClassnames = ref("nyk-signup-step__content--animation")
    const cprInput = ref(props.getNamedFieldInputData.cpr)

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })

    const handleHomeOwnerInput = (bool) => {
      homeOwnerInput.value = bool
    }

    const handleUrgentAccountInput = (bool) => {
      urgentAccountInput.value = bool
    }

    return {
      firstNameInput,
      lastNameInput,
      emailInput,
      phoneInput,
      homeOwnerInput,
      cprInput,
      cprFormatting,
      urgentAccountInput,
      handleHomeOwnerInput,
      handleUrgentAccountInput,
      contentClassnames,
    }
  },
}
