import { ref, onMounted } from "vue"
import addScrollCallback from "../../foundation/js/addScrollCallback"

export default {
  name: "StickyBox",
  props: {
    targetEndId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const classModifiers = ref(false)
    const stickyBox = ref(null)

    onMounted(() => {
      const rect = stickyBox.value.getBoundingClientRect()

      const initTargetPos = rect.top + rect.height + window.scrollY

      let initTargetEndPos = undefined

      if (props.targetEndId) {
        const endTarget = document.getElementById(props.targetEndId)
        const endTargetRect = endTarget?.getBoundingClientRect()
        if (endTargetRect) {
          initTargetEndPos = endTargetRect.top + window.scrollY - window.innerHeight
        }
      }

      addScrollCallback(() => {
        classModifiers.value = initTargetPos < window.scrollY

        if (initTargetEndPos && initTargetEndPos < window.scrollY) {
          classModifiers.value = false
        }
      })
    })

    return {
      stickyBox,
      classModifiers,
    }
  },
}
