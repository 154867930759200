import { createApp } from "vue"
import SendCalculation from "./SendCalculation"

const el = ".nyk-component--send-calculation"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
      DOM.forEach((SendCalculationDOM) => {

      const props = {
        labels: JSON.parse(SendCalculationDOM.dataset.labels),
        salesforceidApiUrl: SendCalculationDOM.dataset.salesforceidApiUrl,
        sendCalculationApiUrl: SendCalculationDOM.dataset.sendcalculationApiUrl,
        userInformationApiUrl: SendCalculationDOM.dataset.userinformationApiUrl,
        calculatorType: SendCalculationDOM.dataset.calculatorType

      }
      const component = createApp(SendCalculation, props).use(Store)
      component.mount(SendCalculationDOM)
    })
  }
}
 