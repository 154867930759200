import {createApp} from 'vue'
import ContactStepIntro from './ContactStepIntro'

const el = '.nyk-component--contact-step-intro'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((ContactStepIntroDOM)=>{
            const component = createApp(ContactStepIntro).use(Store)
            component.mount(ContactStepIntroDOM)
        })
    }
}