import { computed, ref, watch, onMounted } from "vue"
import { useStore } from "vuex"
import SearchOverlay from "../../../Frontend/src/components/SearchOverlay/SearchOverlay.vue"
import {
    getNavigationList,
    setNextItemInFocus,
    setPrevItemInFocus,
    handleLoopTrap,
    handleNavigateBackInSubmenu,
    handleNavigateForwardInSubmenu,
    handleNavigateToPreviousListInSubmenu,
    handleNavigateToNextListInSubmenu,
    navigateInMobileSubmenu,
} from "../../../Frontend/src/foundation/js/menu-key-helper"
import addScrollCallback from "../../../Frontend/src/foundation/js/addScrollCallback"

export default {
    name: "Menu",
    components: {
        SearchOverlay,
    },
    props: {
        overlayId: {
            type: String,
            required: true,
        },
        searchUrl: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore()
        const BurgermenuStateEnum = Object.freeze({
            PageStart: "",
            Open: "nyk-menu__top-menu__bottom__container__actions__burgermenu--open",
            Close: "nyk-menu__top-menu__bottom__container__actions__burgermenu--close",
        })

        const burgermenuState = ref(BurgermenuStateEnum.PageStart)
        const searchInput = ref("")
        const menu = ref(document.querySelector(".nyk-menu"))
        const menuClasses = ref("")
        const menuSticky = ref(false)
        const menuScrolling = ref(false)

        let menuIsInView = false
        let currentScrollTopValGeneral = window.scrollY

        let options = {
            root: document.querySelector("#scrollArea"),
            rootMargin: "0px",
            threshold: 0,
        }

        const menuBottomInViewCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    menuScrolling.value = true
                }
            })
        }

        const menuTopInViewCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    menuIsInView = entry.isIntersecting
                    menuScrolling.value = false
                    menuSticky.value = false
                } else {
                    menuIsInView = entry.isIntersecting
                }
            })
        }

        onMounted(() => {
            menu.value = document.querySelector(".nyk-menu")

            const menuBottom = menu.value.querySelector(".nyk-menu__top-menu__bottom")
            const menuTop = menu.value.querySelector(".nyk-menu__top-menu")
            let menuBottomObserver = new IntersectionObserver(menuBottomInViewCallback, options)
            let menuTopObserver = new IntersectionObserver(menuTopInViewCallback, {
                root: document.querySelector("#scrollArea"),
                rootMargin: "-80px",
                threshold: 0,
            })
            menuBottomObserver.observe(menuBottom)
            menuTopObserver.observe(menuTop)

            addScrollCallback(() => {
                let scrollingDown = currentScrollTopValGeneral < window.scrollY
                let scrollingUp = currentScrollTopValGeneral > window.scrollY

                if (scrollingUp && !menuIsInView) {
                    menuScrolling.value = false
                    menuSticky.value = true
                }
                if (scrollingDown) {
                    menuSticky.value = false
                }

                // updating current scroll pos
                currentScrollTopValGeneral = window.scrollY
            })
        })

        const burgermenuUpdatedState = computed(() => {
            switch (burgermenuState.value) {
                case BurgermenuStateEnum.PageStart:
                    if (submenuOpen.value) {
                        burgermenuState.value = BurgermenuStateEnum.Open
                        return BurgermenuStateEnum.Open
                    } else {
                        burgermenuState.value = BurgermenuStateEnum.PageStart
                        return BurgermenuStateEnum.PageStart
                    }
                default:
                    if (submenuOpen.value) {
                        burgermenuState.value = BurgermenuStateEnum.Open
                        return BurgermenuStateEnum.Open
                    } else {
                        burgermenuState.value = BurgermenuStateEnum.Close
                        return BurgermenuStateEnum.Close
                    }
            }
        })

        // START - Handle in the store
        const otherSitesDropdownShown = computed(() => {
            return store.state.menu.otherSitesDropdownShown
        })

        const languageDropdownShown = computed(() => {
            return store.state.menu.languageDropdownShown
        })

        const topLevelPagesDropdownShown = computed(() => {
            return store.state.menu.topLevelPagesDropdownShown
        })

        const submenuOpen = computed(() => {
            const overlay = document.getElementById(props.overlayId)
            if (overlay) {
                overlay.classList.toggle("nyk-overlay--shown", store.state.menu.submenuOpen)
            }
            document.querySelector("body").classList.toggle("nyk-submenu-open", store.state.menu.submenuOpen)
            return store.state.menu.submenuOpen
        })

        const activeSubmenuDesktop = computed(() => {
            const activeMenu = store.state.menu.activeSubmenuDesktop
            if (activeMenu === "") {
                unSelectAllMenuItems()
            }
            return activeMenu
        })

        const searchOverlayShown = computed(() => {
            return store.state.menu.searchOverlayShown
        })

        const searchOverlayMobileNavigateBack = computed(() => {
            return store.state.menu.searchOverlayMobileNavigateBack
        })

        const getMenuClasses = computed(() => {
            const submenuOpenClass = submenuOpen.value ? "nyk-menu--submenu-open" : ""
            const menuStickyClass = menuSticky.value ? "nyk-menu--sticky-top" : ""
            const menuScrollingClass = menuScrolling.value ? "nyk-menu--scrolling" : ""

            return (menuClasses.value = `${submenuOpenClass} ${menuStickyClass} ${menuScrollingClass}`)
        })

        watch(menuSticky, () => {
            document.querySelector("body").classList.toggle("nyk-menu-is-sticky", menuSticky.value)
        })

        watch(searchOverlayMobileNavigateBack, (navigate) => {
            if (navigate) {
                store.dispatch("menu/searchOverlayShown", { open: false })
                store.dispatch("menu/searchOverlayMobileNavigateBack", { navigate: false })
            }
        })

        const unSelectAllMenuItems = () => {
            const menuItems = menu.value?.querySelectorAll(".nyk-menu__top-menu__bottom__container__menu-items__item__link")
            menuItems?.forEach((item) => item.classList.remove("nyk-menu__top-menu__bottom__container__menu-items__item__link--active"))
        }

        const toggleOtherSitesDropdown = () => {
            console.log("toggleOtherSitesDropdown")
            store.dispatch("menu/otherSitesDropdownShown", { open: !otherSitesDropdownShown.value })
            if (otherSitesDropdownShown.value) {
                setTimeout(() => {
                    document.addEventListener("click", closeOtherSitesDropdownListener)
                }, 300)
            }
        }

        const closeOtherSitesDropdownListener = () => {
            store.dispatch("menu/otherSitesDropdownShown", { open: false })
            document.removeEventListener("click", closeOtherSitesDropdownListener)
        }

        const toggleLanguagesDropdown = () => {
            store.dispatch("menu/languageDropdownShown", { open: !languageDropdownShown.value })
            if (languageDropdownShown.value) {
                setTimeout(() => {
                    document.addEventListener("click", closeLanguagesDropdownListener)
                }, 300)
            }
        }

        const closeLanguagesDropdownListener = () => {
            store.dispatch("menu/languageDropdownShown", { open: false })
            document.removeEventListener("click", closeLanguagesDropdownListener)
        }

        const toggleTopLevelPagesMobileDropdown = () => {
            store.dispatch("menu/topLevelPagesDropdownShown", { open: !topLevelPagesDropdownShown.value })
        }

        const toggleSubmenu = () => {
            store.dispatch("menu/searchOverlayShown", { open: searchOverlayShown.value })
            store.dispatch("menu/submenuOpen", { open: !submenuOpen.value })

            if (!submenuOpen.value) {
                store.dispatch("menu/activeSubmenuDesktop", { menu: "" })
                store.dispatch("menu/searchOverlayShown", { open: false })
            }

            const activeMenu = store.state.menu.activeSubmenuDesktop

            if (submenuOpen.value && activeMenu === "") {
                findAndSetActiveDesktopSubmenu()
            }
        }
        // END - Handle in the store

        const findAndSetActiveDesktopSubmenu = () => {
            const activeItem = menu.value.querySelector(".nyk-menu__top-menu__bottom__container__menu-items__item__link--active")
            if (activeItem) {
                const menuId = activeItem.dataset.menuId
                store.dispatch("menu/activeSubmenuDesktop", { menu: menuId })
                menu.value
                    .querySelector(`.nyk-js--submenu-desktop[data-submenu="${menuId}"]`)
                    ?.classList.add("nyk-menu__submenu__content__items--shown")
            }
        }

        const showMobileSubmenuSubLevel = (target) => {
            hideAllMobileSubmenus()
            const targetMobileSubmenu = menu.value.querySelector(`[data-submenu-sublevel="${target}"]`)
            targetMobileSubmenu.classList.add("nyk-menu__submenu__content__items-mobile__second-level-submenu--shown")

            const secondLevelItems = targetMobileSubmenu.querySelectorAll(".nyk-js-mobile-submenu-second-level-item")
            secondLevelItems[0].focus()

            toggleMobileInitSubmenu(true)
        }

        const hideMobileFirstLevelSubmenu = () => {
            hideAllMobileSubmenus()
            toggleMobileInitSubmenu(false)
        }

        const hideAllMobileSubmenus = () => {
            const activeMobileSubmenus = menu.value.querySelectorAll(
                ".nyk-menu__submenu__content__items-mobile__second-level-submenu--shown"
            )
            activeMobileSubmenus.forEach((item) =>
                item.classList.remove("nyk-menu__submenu__content__items-mobile__second-level-submenu--shown")
            )
        }

        const toggleMobileInitSubmenu = (show) => {
            menu.value
                .querySelector(".nyk-menu__submenu__content__items-mobile__list")
                .classList.toggle("nyk-menu__submenu__content__items-mobile__list--hide", show)
            menu.value
                .querySelector(".nyk-menu__submenu__content__items-mobile__selector")
                .classList.toggle("nyk-menu__submenu__content__items-mobile__selector--hide", show)
        }

        const navigateToSearch = () => {
            window.location.href = props.searchUrl + `?q=${searchInput.value}`
        }

        // Keyboard navigations
        const navigateForwardInMenu = (e) => {
            setNextItemInFocus(e.target, getNavigationList("nyk-js--menu-item"))
        }

        const navigateBackInMenu = (e) => {
            setPrevItemInFocus(e.target, getNavigationList("nyk-js--menu-item"))
        }

        const handleMenuDropdown = () => {
            toggleOtherSitesDropdown()

            setTimeout(() => {
                const dropdownLinks = menu.value.querySelectorAll(".nyk-js--top-menu-dropdown-link")
                dropdownLinks?.forEach((link) => {
                    if (link.dataset.order === "first") {
                        link.focus()
                    }
                })
            }, 50)
        }

        const handleLanguagesDropdown = () => {
            toggleLanguagesDropdown()

            setTimeout(() => {
                const dropdownLinks = menu.value.querySelectorAll(".nyk-js--top-menu-lang-dropdown-link")
                dropdownLinks?.forEach((link) => {
                    if (link.dataset.order === "first") {
                        link.focus()
                    }
                })
            }, 50)
        }

        const handleDownLoop = (e) => {
            handleLoopTrap(e, true)
        }

        const handleUpLoop = (e) => {
            handleLoopTrap(e, false)
        }

        const closeOtherSitesDropdown = () => {
            toggleOtherSitesDropdown()
            const dropdown = menu.value.querySelector(".nyk-js--top-menu-dropdown-button")
            dropdown?.focus()
        }

        const closeLanguageDropdown = (e) => {
            const parent = e.target.closest(".nyk-menu__top-menu__top__menu-items__item--dropdown")
            const dropdown = parent?.querySelector(".nyk-js--top-menu-lang-dropdown-button")

            toggleLanguagesDropdown()

            if (dropdown) {
                dropdown?.focus()
            } else {
                const dropdownDefault = menu.value.querySelector(".nyk-js--top-menu-lang-dropdown-button")
                dropdownDefault?.focus()
            }
        }

        const navigateToSubmenu = () => {
            if (submenuOpen.value) {
                closeSubmenu()
                return
            }

            toggleSubmenu()
            hideMobileFirstLevelSubmenu()

            setTimeout(() => {
                const subNavigationList = getNavigationList("nyk-js--submenu-item")

                if (subNavigationList[0]?.closest(".nyk-js--mobile-submenu")) {
                    subNavigationList[0].focus()
                    if (subNavigationList[0] !== document.activeElement) {
                        setNextItemInFocus(subNavigationList[0], subNavigationList)
                    }
                } else {
                    subNavigationList[1].focus()
                    if (subNavigationList[1] !== document.activeElement) {
                        setNextItemInFocus(subNavigationList[0], subNavigationList)
                    }
                }
            }, 100)
        }

        const closeSubmenu = () => {
            const navigationThree = getNavigationList("nyk-js--menu-item")
            const activeItem = navigationThree.find((item) => item.getAttribute("aria-expanded") === "true")

            if (activeItem) {
                activeItem.focus()
            } else {
                navigationThree[navigationThree.length - 1].focus()
            }

            toggleSubmenu()
        }

        const navigateBackInSubmenu = (e) => {
            handleNavigateBackInSubmenu(e)
        }

        const navigateForwardInSubmenu = (e) => {
            handleNavigateForwardInSubmenu(e)
        }

        const navigateToPreviousListInSubmenu = (e) => {
            handleNavigateToPreviousListInSubmenu(e)
        }

        const navigateToNextListInSubmenu = (e) => {
            handleNavigateToNextListInSubmenu(e)
        }

        const navigateForwardInMobileSubmenu = (e) => {
            navigateInMobileSubmenu(e, true)
        }

        const navigateBackInMobileSubmenu = (e) => {
            navigateInMobileSubmenu(e, false)
        }

        const openTopLevelPagesMobileDropdown = () => {
            store.dispatch("menu/topLevelPagesDropdownShown", { open: true })

            setTimeout(() => {
                const topLevelPagesDropdownItems = menu.value.querySelectorAll(".nyk-js--mobile-top-level-item")
                topLevelPagesDropdownItems[0].focus()
            }, 50)
        }

        const closeTopLevelPagesMobileDropdown = (e) => {
            store.dispatch("menu/topLevelPagesDropdownShown", { open: false })

            const parent = e.target.closest(".nyk-js--mobile-selector")
            const selector = parent.querySelector(".nyk-js--mobile-submenu-item")
            selector?.focus()
        }

        const navigateBackToMobileFirstLevelMenu = (e) => {
            hideMobileFirstLevelSubmenu()

            setTimeout(() => {
                const list = menu.value.querySelector(".nyk-js--mobile-first-level-list")
                const items = list.querySelectorAll(".nyk-js--mobile-submenu-item")
                items[0].focus()
            }, 50)
        }

        const openSearchOverlay = () => {
            if (!submenuOpen.value) {
                store.dispatch("menu/submenuOpen", { open: true })
            }

            if (submenuOpen.value && searchOverlayShown.value) {
                store.dispatch("menu/submenuOpen", { open: false })
            }
            store.dispatch("menu/searchOverlayShown", { open: !searchOverlayShown.value })
            store.dispatch("menu/activeSubmenuDesktop", { menu: "" })

            setTimeout(() => {
                const overlaySearchInput = menu.value.querySelector(".nyk-js--search-overlay-input")
                if (overlaySearchInput) {
                    overlaySearchInput.value = ""
                    overlaySearchInput.focus()
                }
            }, 50)
        }

        const hideAllDesktopSubmenus = () => {
            const submenus = menu.value.querySelectorAll(".nyk-js--submenu-desktop")
            submenus.forEach((item) => item.classList.remove("nyk-menu__submenu__content__items--shown"))

            const activeSubmenu = menu.value.querySelector(`.nyk-js--submenu-desktop[data-submenu="${activeSubmenuDesktop.value}"]`)
            activeSubmenu?.classList.add("nyk-menu__submenu__content__items--shown")
        }

        const setActiveMenuDesktop = () => {
            const menuItems = menu.value.querySelectorAll(".nyk-js--menu-item[data-menu-id]")
            menuItems.forEach((item) => item.classList.remove("nyk-menu__top-menu__bottom__container__menu-items__item__link--active"))

            const activeMenu = menu.value.querySelector(`.nyk-js--menu-item[data-menu-id="${activeSubmenuDesktop.value}"]`)
            activeMenu?.classList.add("nyk-menu__top-menu__bottom__container__menu-items__item__link--active")
        }

        const handleSubmenuDesktop = () => {
            hideAllDesktopSubmenus()
            setActiveMenuDesktop()
        }

        const showSubmenuDesktop = (e) => {
            const menuId = e.target.dataset.menuId

            if (!submenuOpen.value || activeSubmenuDesktop.value === menuId) {
                toggleSubmenu()
            }

            store.dispatch("menu/searchOverlayShown", { open: false })
            store.dispatch("menu/activeSubmenuDesktop", { menu: menuId })
            handleSubmenuDesktop()
        }

        const navigateToSubmenuDesktop = (e) => {
            const menuId = e.target.dataset.menuId

            if (!submenuOpen.value || activeSubmenuDesktop.value === menuId) {
                navigateToSubmenu()
            }

            store.dispatch("menu/activeSubmenuDesktop", { menu: menuId })
            handleSubmenuDesktop()
        }

        return {
            otherSitesDropdownShown,
            languageDropdownShown,
            topLevelPagesDropdownShown,
            submenuOpen,
            toggleOtherSitesDropdown,
            toggleSubmenu,
            burgermenuState,
            burgermenuUpdatedState,
            toggleTopLevelPagesMobileDropdown,
            showMobileSubmenuSubLevel,
            hideMobileFirstLevelSubmenu,
            navigateToSearch,
            searchInput,
            navigateBackInMenu,
            navigateForwardInMenu,
            handleMenuDropdown,
            handleDownLoop,
            handleUpLoop,
            closeOtherSitesDropdown,
            navigateToSubmenu,
            closeSubmenu,
            navigateBackInSubmenu,
            navigateForwardInSubmenu,
            navigateToNextListInSubmenu,
            navigateToPreviousListInSubmenu,
            navigateForwardInMobileSubmenu,
            navigateBackInMobileSubmenu,
            openTopLevelPagesMobileDropdown,
            closeTopLevelPagesMobileDropdown,
            navigateBackToMobileFirstLevelMenu,
            searchOverlayShown,
            openSearchOverlay,
            showSubmenuDesktop,
            activeSubmenuDesktop,
            navigateToSubmenuDesktop,
            getMenuClasses,
            toggleLanguagesDropdown,
            handleLanguagesDropdown,
            closeLanguageDropdown,
        }
    },
}
