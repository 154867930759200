import { createApp } from "vue"
import SearchOverlay from "./SearchOverlay"

const el = ".nyk-component--search-overlay"

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((SearchOverlayDOM) => {
            const props = {
                searchUrl: SearchOverlayDOM.dataset.searchUrl,
                suggestionUrl: SearchOverlayDOM.dataset.suggestionUrl,
                searchResultTitle: SearchOverlayDOM.dataset.searchResultTitle,
                searchPlaceholder: SearchOverlayDOM.dataset.searchPlaceholder,
                searchSuggestionsJson: SearchOverlayDOM.dataset.searchSuggestions
                    ? JSON.parse(SearchOverlayDOM.dataset.searchSuggestions)
                    : [],
                cardTopJson: SearchOverlayDOM.dataset.cardTop ? JSON.parse(SearchOverlayDOM.dataset.cardTop) : {},
                cardBottomJson: SearchOverlayDOM.dataset.cardBottom ? JSON.parse(SearchOverlayDOM.dataset.cardBottom) : {},
            }

            const component = createApp(SearchOverlay, props).use(Store)
            component.mount(SearchOverlayDOM)
        })
    }
}
