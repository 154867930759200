import {createApp} from 'vue'
import options from './ThemeSwitch.options'

const el = '.nyk-component--theme-switch'

export default function(Store) {
    const DOM = document.querySelectorAll(el)
    DOM.forEach((ThemeSwitchDOM) => {
        const props = {
            theme: ThemeSwitchDOM.dataset.theme
        }
        const template = ThemeSwitchDOM.outerHTML
        options.template = template
        const component = createApp(options, props).use(Store)
        component.mount(ThemeSwitchDOM)
    })
}

