const getDistanceFromLatLonInKm = function(placeSelected, center) {
    const lat1 = placeSelected.geometry.location.lat()
    const lng1 = placeSelected.geometry.location.lng()
    const lat2 = center.latitude
    const lng2 = center.longitude
    
    // Radius of the earth in km
    const earthRadiusInKm = 6371;

    // Convert degree to radian
    const deg2rad = (deg) => deg * (Math.PI/180);

    // Calculate differences and convert to radians
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lng2 - lng1);

    // Calculate the square of half the chord length between latitude and longitude
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
               Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
               Math.sin(dLon / 2) * Math.sin(dLon / 2);

    // Calculate the angular distance in radians
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate distance in kilometers
    const distanceInKm = earthRadiusInKm * c;

    return distanceInKm;
}

export default getDistanceFromLatLonInKm