import {createApp} from 'vue'
import options from './TheForms.options'

const el = '.nyk-component--the-forms'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    DOM.forEach((TheFormsDOM)=>{
        const template = TheFormsDOM.outerHTML
        options.template = template
        const component = createApp(options).use(Store)
        component.mount(TheFormsDOM)
    })
}

