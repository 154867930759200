<script>
import options from "./ContactStepComments.options"
export default options
</script>

<template>
  <article class="nyk-signup-step nyk-contact-step-comments">
    <div class="nyk-signup-step__content" :class="contentClassnames">
      <div
        class="nyk-signup-step__content__section--66-width nyk-signup-step__content__section--full-width-mobile">
        <div class="nyk-signup-step__header">
          <h2 class="nyk-signup-step__title">{{ currentStepTexts.title }}</h2>
          <p class="nyk-signup-step__stepcounter">Step {{ stepCounterText }}</p>
        </div>
      </div>

      <div class="nyk-signup-step__mt-auto nyk-signup-step__content__section--full-width">
        <div
          class="nyk-signup-step__mt-auto nyk-signup-step__content__section--66-width nyk-signup-step__content__section--full-width-mobile">
          <input
            class="nyk-signup-step__input"
            :placeholder="labels.commentPlaceholder ?? 'Evt. kommentar'"
            v-model="commentsInput"
            v-on:keyup.enter="nextStepCallback([{ field: 'comment', value: commentsInput }])" />
        </div>

        <SignupStepActions
          :previousStepCallback="previousStepCallback"
          :nextStepCallback="() => nextStepCallback([{ field: 'comment', value: commentsInput }])"
          :nextStepButtonText="currentStepTexts.next"
          :stepCounterText="stepCounterText"
          :hasPreviousStep="hasPreviousStep" />
      </div>
    </div>
  </article>
</template>
