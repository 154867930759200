import { createApp } from "vue"
import SearchInputField from "./SearchInputField"

const el = ".nyk-component--search-input-field"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((SearchInputFieldDOM) => {
      const props = {
        placeholder: SearchInputFieldDOM.dataset.placeholder,
        initSearchParam: SearchInputFieldDOM.dataset.initSearchParam,
        onChangeCallback: SearchInputFieldDOM.dataset.onChangeCallback ?? null,
        onEnterCallback: SearchInputFieldDOM.dataset.onEnterCallback ?? null,
        redirectUrl: SearchInputFieldDOM.dataset.redirectUrl ?? null,
      }
      const component = createApp(SearchInputField, props).use(Store)
      component.mount(SearchInputFieldDOM)
    })
  }
}
