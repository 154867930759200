import { ref, computed, watch, onMounted, onBeforeMount } from "vue"
import { useStore } from "vuex"
import debounce from "../../foundation/js/debounce"
import SiteSearchingResult from "../SiteSearchingResult/SiteSearchingResult.vue"
import FilterTabs from "../FilterTabs/FilterTabs.vue"
import SearchInputField from "../SearchInputField/SearchInputField.vue"

export default {
  name: "SiteSearching",
  components: {
    SiteSearchingResult,
    SearchInputField,
    FilterTabs,
  },
  props: {
    searchUrl: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: false,
      default: null
    },
    labels: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const store = useStore()
    const searchInput = ref("")
    const siteSearchingContainer = ref(null)

    onBeforeMount(() => {
      siteSearchingContainer.value = document.querySelector(
        ".nyk-site-searching"
      )

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const searchQuery = urlParams.get("q")
      searchInput.value = searchQuery

      inputChanged(searchQuery)
    })

    const searchResults = computed(() => {
      return store.state.search.results
    })

    const unfilteredNumberOfResults = computed(() => {
      return store.state.search.unfilteredNumberOfResults
    })

    const searchFacts = computed(() => {
      const array = []
      const storeFacts = store.state.search.facts
      if (storeFacts.length > 0) {
        array.push({
          title: `${props.labels.allTabLabel} (${unfilteredNumberOfResults.value})`,
          id: "",
        })
        storeFacts.map((fact) => {
          array.push({ title: `${fact.term} (${fact.count})`, id: fact.term })
        })
      }

      return array
    })

    const activeFilter = computed(() => {
      return store.state.search.activeFilter
    })

    const currentPage = computed(() => {
      return store.state.search.pageNumber
    })

    const pageTotalSize = computed(() => {
      return store.state.search.pageTotalSize
    })

    const inputIsNotEmpty = computed(() => {
      return searchInput.value !== ""
    })

    const inputChanged = (value) => {
      searchInput.value = value
      if (value) {
        store.dispatch("search/activeFilter", { filter: "" })
        store.dispatch("search/performSearch", {
          query: searchInput.value,
          lang: props.lang,
          apiUrl: props.searchUrl,
          page: 1,
        })
      } else {
        store.dispatch("search/clearResults")
      }
      updateUrl(value)
    }

    const updateUrl = (value) => {
      const url = new URL(window.location)
      url.searchParams.set("q", value)
      window.history.pushState({}, "", url)
    }

    const changeFilter = (tabId) => {
      store.dispatch("search/activeFilter", { filter: tabId })
      store.dispatch("search/performSearch", {
        query: searchInput.value,
        apiUrl: props.searchUrl,
        page: 1,
        searchSection: tabId,
      })
    }

    const getMoreResults = () => {
      store.dispatch("search/performSearch", {
        query: searchInput.value,
        apiUrl: props.searchUrl,
        page: currentPage.value + 1,
      })
      store.dispatch("search/pageNumber", { page: currentPage.value + 1 })
    }

    const emptyInput = () => {
      store.dispatch("search/clearResults")
      searchInput.value = ""
      const input = siteSearchingContainer.value.querySelector(
        ".nyk-js--site-searching-input"
      )
      input?.focus()
    }

    return {
      searchInput,
      searchResults,
      searchFacts,
      unfilteredNumberOfResults,
      activeFilter,
      changeFilter,
      currentPage,
      inputChanged,
      pageTotalSize,
      getMoreResults,
      emptyInput,
      inputIsNotEmpty,
    }
  },
}
