const getCenterInformation =  function(getCenterInformationsUrl){

    const request = new Request(getCenterInformationsUrl)

    return fetch(getCenterInformationsUrl)
    .then((res)=>{
        if(!res.ok) throw new Error(res.status)
            return res.json()
    })
    .then((json)=>{
        return json.mapCenterInformations
    })
    .catch((err)=>console.log(`Error getting center information from url ${getCenterInformationsUrl}`,err))
}

export default getCenterInformation