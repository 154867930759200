import { createApp } from 'vue'
import FormGenericInput from './FormGenericInput'
import handleComponentProps from '../../../../../../../Frontend/src/foundation/js/utils/handleComponentProps.js'

const el = '.nyk-component--form-generic-input'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((FormGenericInputDOM) => {
            const props = handleComponentProps(FormGenericInput, FormGenericInputDOM)
            const component = createApp(FormGenericInput, props).use(Store)
            component.mount(FormGenericInputDOM)
        })
    }
}
