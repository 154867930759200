import { createApp } from "vue"
import ContactCardLeadWithoutPermission from "./ContactCard"

const el = ".nyk-component--contact-card"

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((ContactCardLeadWithoutPermissionDOM) => {
            const props = {
                labels: JSON.parse(ContactCardLeadWithoutPermissionDOM.dataset.labels),
                formId: ContactCardLeadWithoutPermissionDOM.dataset.formId,
                apiUrl: ContactCardLeadWithoutPermissionDOM.dataset.apiUrl,
                newsletterApiUrl: ContactCardLeadWithoutPermissionDOM.dataset.newsletterApiUrl,
                newsletters: ContactCardLeadWithoutPermissionDOM.dataset.newsletters
                    ? JSON.parse(ContactCardLeadWithoutPermissionDOM.dataset.newsletters)
                    : [],
                alignIntroImageRight: ContactCardLeadWithoutPermissionDOM.dataset.alignIntroImageRight === "true",
                imageSource: ContactCardLeadWithoutPermissionDOM.dataset.imageSource,
                backgroundColor: ContactCardLeadWithoutPermissionDOM.dataset.backgroundColor ?? null,
                assignTo: ContactCardLeadWithoutPermissionDOM.dataset.assignTo,
                enableUserTrack: ContactCardLeadWithoutPermissionDOM.dataset.enableUserTrack
            }
            const component = createApp(ContactCardLeadWithoutPermission, props).use(Store)
            component.mount(ContactCardLeadWithoutPermissionDOM)
        })
    }
}
