import { computed, onMounted, ref } from "vue"
import GoggleMapsWithMarkers from "../../../../Frontend/src/components/GoogleMapsWithMarkers/GoogleMapsWithMarkers.vue"
import MultiSelectDropdown from "../../../../Frontend/src/components/MultiSelectDropdown/MultiSelectDropdown.vue"

import zoomMapToCenters from "./methods/zoomMapToCenters"
import setFocusPoints from "./methods/setFocusPoints"
import initGooglePlacesAutocomplete from "./methods/initGooglePlacesAutocomplete"
import mapCategoriesToMultiSelectOptions from "./methods/mapCategoriesToMultiSelectOptions"
import getDistanceFromLatLonInKm from "./methods/getDistanceFromLatLonInKm"

export default {
  name: "CenterMap",
  props: {
    categories: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    mapkey: {
      type: String,
      required: true,
    },
    centerInformation: {
      type: Array,
      required: true,
    }
  },
  setup(props) {
    const centerInformationComplete = ref(props.centerInformation)
    const centerInformationFiltered = ref(props.centerInformation) // map will display these locations
    const googlePlacesSearchField = ref(null)
    const placeSelected = ref(null)
    const focusPoints = ref([]) // map will zoom to these focus points
    const placeSearchQuery = ref("")
    const showMapsMobile = ref(true)
    const showListMobileCount = ref(3)

    const multiSelectCategoriesOptions = ref(mapCategoriesToMultiSelectOptions(props.categories))

    onMounted(async () => {
      initGooglePlacesAutocomplete(googlePlacesSearchField.value, filterCentersAndZoom)
    })

    const showFilteredResults = computed(() => {
      let show = false
      if (centerInformationFiltered.value && centerInformationFiltered.value.length === 3) {
        show = true
      }
      return show
    })

    // controlling focus points and pins data

    const filterCentersAndZoom = function (places) {
      showListMobileCount.value = 3
      const searchHasHits = !!places.getPlace().geometry
      if (searchHasHits) {
        placeSelected.value = places.getPlace()
        centerInformationFiltered.value = zoomMapToCenters(placeSelected, centerInformationComplete.value)
        focusPoints.value = setFocusPoints(centerInformationFiltered.value)
      } else {
        placeSelected.value = null
        resetCenterSearch()
      }
    }

    const resetCenterSearch = function () {
      googlePlacesSearchField.value.value = ""
      centerInformationFiltered.value = centerInformationComplete.value
      focusPoints.value = setFocusPoints(centerInformationFiltered.value)
      showListMobileCount.value = 3
    }

    // handling category selection

    const filterCategories = function (userSelection) {
      showListMobileCount.value = 3
      const selectedCategoryIds = userSelection.filter((option) => option.selected).map((option) => parseInt(option.value))

      centerInformationFiltered.value = centerInformationComplete.value.filter((center) => {
        return center.categories.some((category) => selectedCategoryIds.includes(category))
      })

      if (centerInformationFiltered.value.length) {
        focusPoints.value = setFocusPoints(centerInformationFiltered.value)
      } else {
        resetCenterSearch()
      }
    }

    const getDistance = (info) => {
      if (!placeSelected.value) return
      const distance = getDistanceFromLatLonInKm(placeSelected.value, info)
      return `${distance.toFixed(2)} km`
    }

    const handleShowMapMobile = () => {
      showMapsMobile.value = true
    }

    const handleShowListResultsMobile = () => {
      showMapsMobile.value = false
    }

    const handleShowAllListMobile = () => {
      showListMobileCount.value = 10000
    }

    return {
      centerInformationFiltered,
      filterCategories,
      focusPoints,
      googlePlacesSearchField,
      multiSelectCategoriesOptions,
      placeSearchQuery,
      showFilteredResults,
      resetCenterSearch,
      getDistance,
      showMapsMobile,
      handleShowMapMobile,
      handleShowListResultsMobile,
      showListMobileCount,
      handleShowAllListMobile,
    }
  },
  components: {
    GoggleMapsWithMarkers,
    MultiSelectDropdown,
  },
}
