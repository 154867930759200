export default {
    name: "ThemeSwitch",
    props: {
        theme: {
            type: String,
            required: true
        }
    },
    setup(props){
        
        const switchTheme = (e) => {
            document.querySelector('HTML').classList.toggle(props.theme, e.target.checked)
        }

        return {
            switchTheme
        }
    }
}