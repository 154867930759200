import { createApp } from "vue"
import FeedbackStepTwo from "./FeedbackStepTwo"

const el = ".nyk-component--feedback-step-two"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((FeedbackStepTwoDOM) => {
      const props = {
        title: FeedbackStepTwoDOM.dataset.title ?? "",
        description: FeedbackStepTwoDOM.dataset.description ?? "",
        placeholder: FeedbackStepTwoDOM.dataset.feedbackPlaceholder ?? "",
        callback: FeedbackStepTwoDOM.dataset.callback ?? null,
        buttonText: FeedbackStepTwoDOM.dataset.buttonText ?? "",
      }
      const component = createApp(FeedbackStepTwo, props).use(Store)
      component.mount(FeedbackStepTwoDOM)
    })
  }
}
