import getDistanceFromLatLonInKm from "./getDistanceFromLatLonInKm"

const zoomMapToCenters = function (placeSelected, centerInformation) {
  const maxFilteredCentersToDisplay = 3
  const hasSelectedPlace = !!placeSelected.value
  const hasGeometry = hasSelectedPlace && placeSelected.value.geometry

  if (hasGeometry) {
    return centerInformation
      .sort((centerA, centerB) => {
        return (
          getDistanceFromLatLonInKm(placeSelected.value, centerA) -
          getDistanceFromLatLonInKm(placeSelected.value, centerB)
        )
      })
      .slice(0, maxFilteredCentersToDisplay)
  }
}

export default zoomMapToCenters
