<script>
import options from './FormGenericInput.options'
export default options
</script>

<template>
    <div class="nyk-form nyk-js--form" :class="{ 'nyk-form--error': isValidationError }">
        <div class="nyk-form__content">
            <input
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :type="type"
                class="nyk-form__content__input"
                :id="inputName"
                :name="inputName"
                :aria-describedby="`${inputName}__desc`"
                :aria-invalid="isValidationError"
                :required="isRequired"
                :aria-required="isRequired" />
            <label class="nyk-form__content__label" :for="inputName">{{ label }}</label>
            <div class="nyk-form__content__prefix" v-if="prefix">{{ prefix }}</div>
            <button type="button" class="nyk-form__content__reset-icon nyk-form-js--reset" aria-label="reset input value">
                <span class="nyk-icon nyk-icon--ui-close"></span>
            </button>
            <div class="nyk-form__content__error-icon">
                <span class="nyk-icon nyk-icon--ui-form-error"></span>
            </div>
        </div>
        <div class="nyk-form__help-message" aria-live="polite">{{ helpMessageLabel }}</div>
        <span class="nyk-form__error-message" aria-live="polite">{{ errorMessageLabel }}</span>
    </div>
</template>
