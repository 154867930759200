import { registerGtmDataLayer } from '../../../../../Features/Blocks/Video/gtmDataLayerService';
export default async function (body, url) {
  let status = null
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      status = res.status
      if (res.ok) {
        return res.json()
      } else {
        throw new Error("Signup to get contacted didn't work")
      }
    })
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.error(err)
      return err
    })

  if (response && response.name && response.email && response.hash) {
      let dataLayer = {
          'event': 'track-event',
          'event_source': 'kontaktformular',
          'eventCategory': 'hashing',
          'eventAction': 'hashing',
          'eventLabel': 'standalone',
          'name': response.name,
          'email': response.email,
          'hfnem': response.hash
      };

      registerGtmDataLayer(dataLayer);
  }
  return {
    response: response,
    status: status,
  }
}
