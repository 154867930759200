<script>
import options from "./FindCenterMapAndEmployees.options"
export default options
</script>

<template>
    <article class="nyk-find-center-map-and-employees">
        <div class="nyk-find-center-map-and-employees__filters">
            <FilterTabs init-active-tab="GoogleMaps" :tabs="tabs" :callback="findCenterFilterChanged" />
        </div>

        <div class="nyk-find-center-map-and-employees__sections">
            <div
                aria-labelledby="GoogleMaps"
                :class="{
                    'nyk-find-center-map-and-employees__sections__content--hidden': !showCenterMaps,
                }">
                <template v-if="centerInformation.length > 0">
                    <CenterMap
                        :categories="categories"
                        :labels="labels"
                        :mapkey="mapkey"
                        :apiUrl="centersApiUrl"
                        :centerInformation="centerInformation" />
                </template>
            </div>

            <div
                aria-labelledby="Employees"
                :class="{
                    'nyk-find-center-map-and-employees__sections__content--hidden': showCenterMaps,
                }">
                <template v-if="centerInformation.length > 0">
                    <FindCenterContactPersons
                        :defaultImage="defaultEmployeesImage"
                        :apiUrl="contactPersonsApiUrl"
                        :centerInformation="centerInformation"
                        :contactUrl="contactUrl" />
                </template>
            </div>
        </div>
    </article>
</template>
