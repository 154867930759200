<script>
import options from "./FeedbackStepThree.options"
export default options
</script>

<template>
  <article class="nyk-feedback-step-three">
    <div class="nyk-feedback-step-three__close">
      <button v-on:click="handleClose" aria-label="afslut feedback" class="nyk-feedback-step-three__close__icon">
        <span class="nyk-icon nyk-icon--ui-close"></span>
      </button>
    </div>

    <h2 class="nyk-feedback-step-three__message">{{ message }}</h2>
  </article>
</template>
