import { createApp } from "vue"
import SignupStepSelectNewsletters from "./SignupStepSelectNewsletters"

const el = ".nyk-component--signup-step-select-newsletters"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((SignupStepSelectNewslettersDOM) => {
      const props = {
        currentStepTexts: {
          title: SignupStepEmailDOM.dataset.title,
          summary: SignupStepEmailDOM.dataset.summary,
        },
      }
      const component = createApp(SignupStepSelectNewsletters, props).use(Store)
      component.mount(SignupStepSelectNewslettersDOM)
    })
  }
}
