import { ref } from "vue"
import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../SignupStepActions/SignupStepActions.vue"

export default {
  name: "SignupStepEmail",
  props: _signupStepsCommonProps,
  components: {
    SignupStepActions,
  },
  setup(props) {
    const firstnameInput = ref(props.getNamedFieldInputData.firstname)
    const lastnameInput = ref(props.getNamedFieldInputData.lastname)

    return {
      firstnameInput,
      lastnameInput,
    }
  },
}
