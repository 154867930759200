import {createApp} from 'vue'
import CalculatorInfoBox from './CalculatorInfoBox'

const el = '.nyk-component--calculator-info-box'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((CalculatorInfoBoxDOM)=>{
            const component = createApp(CalculatorInfoBox).use(Store)
            component.mount(CalculatorInfoBoxDOM)
        })
    }
}