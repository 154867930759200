import { createApp } from "vue"
import FeedbackStepOne from "./FeedbackStepOne"

const el = ".nyk-component--feedback-step-one"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((FeedbackStepOneDOM) => {
      const props = {
        title: FeedbackStepOneDOM.dataset.title ?? "",
        actions: FeedbackStepOneDOM.dataset.actions ?? null,
      }
      const component = createApp(FeedbackStepOne, props).use(Store)
      component.mount(FeedbackStepOneDOM)
    })
  }
}
