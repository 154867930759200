import { onUpdated, ref } from "vue"
import { resetInputValue, fileUploaded } from "../../../../Frontend/src/foundation/js/form-methods"
import { submitJobApplication } from "./JobApplication.options.form-submit"
import emailValidator from "../../../../Frontend/src/foundation/js/inputValidators/emailValidator"
import phoneValidator from "../../../../Frontend/src/foundation/js/inputValidators/phoneValidator"

export default {
  name: "JobApplication",
  components: {},
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    jobPostingId: {
      type: String,
      required: true,
    },
    genderList: {
      type: Object,
      required: true,
    },
    initLabels: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
    successLabels: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const labels = ref(props.initLabels)
    const formFailedErrorMessage = ref("")

    const jobApplicationRef = ref(null)
    const formRef = ref(null)

    const firstNameModel = ref("")
    const lastNameModel = ref("")
    const addressModel = ref("")
    const postalCodeModel = ref("")
    const cityModel = ref("")
    const emailModel = ref("")
    const phoneModel = ref("")

    const firstNameWrapper = ref(null)
    const lastNameWrapper = ref(null)
    const addressWrapper = ref(null)
    const postalCodeWrapper = ref(null)
    const cityWrapper = ref(null)
    const emailWrapper = ref(null)
    const phoneWrapper = ref(null)
    const genderWrapper = ref(null)
    const cvFileWrapper = ref(null)

    const miscFilesWrapper = ref(null)
    const applicationFilesWrapper = ref(null)

    const generalErrorMessage = ref(null)
    const generalErrorWrapper = ref(null)

    const loading = ref(false)

    const validation = ref({
      firstName: false,
      lastName: false,
      address: false,
      postalCode: false,
      city: false,
      email: false,
      phone: false,
      gender: false,
      cv: false,
      totalFileSizeInRange: false,
      oneFileOutOfRange: false,
      miscFileLimitInRange: true,
      totalNumberOfFilesInRange: true,
      mimeType: false,
    })

    const cvFileInput = ref(null)
    const applicationFileInput = ref(null)
    const miscFilesInput = ref(null)

    const cvUploadedFiles = ref(null)

    const emailErrorMessage = ref(null)
    const cvFilesErrorMessage = ref(null)
    const miscFilesErrorMessage = ref(null)
    const applicationFilesErrorMessage = ref(null)
    const totalFileLimit = 7
    const miscFileLimit = 5
    const acceptedFileTypes = ".pdf"
    const acceptedMimeTypes = "application/pdf"
    const maxFileSizeInBytes = 9000000
    const maxAccumulatedFileSizeInBytes = 65000000
    const submitAttempted = ref(false)
    const submitSuccess = ref(false)

    onUpdated(() => {
      if (!submitSuccess.value) {
        emailErrorMessage.value.textContent =
          emailModel.value === ""
            ? labels.value.inputEmailErrorMessage
            : labels.value.inputEmailWrongformatErrorMessage

        validation.value.firstName = firstNameModel.value !== ""
        validation.value.lastName = lastNameModel.value !== ""
        validation.value.address = addressModel.value !== ""
        validation.value.postalCode = postalCodeModel.value !== ""
        validation.value.city = cityModel.value !== ""
        validation.value.email = emailValidator(emailModel.value)

        validation.value.phone = phoneValidator(phoneModel.value)

        if (submitAttempted.value) {
          handleInputUpdated()
        }
      }
    })

    const handleInputUpdated = () => {
      firstNameWrapper.value.classList.toggle("nyk-form--error", !validation.value.firstName)
      lastNameWrapper.value.classList.toggle("nyk-form--error", !validation.value.lastName)
      addressWrapper.value.classList.toggle("nyk-form--error", !validation.value.address)
      postalCodeWrapper.value.classList.toggle("nyk-form--error", !validation.value.postalCode)
      cityWrapper.value.classList.toggle("nyk-form--error", !validation.value.city)
      emailWrapper.value.classList.toggle("nyk-form--error", !validation.value.email)
      phoneWrapper.value.classList.toggle("nyk-form--error", !validation.value.phone)
      genderWrapper.value.classList.toggle("nyk-form--error", !validation.value.gender)
      cvFileWrapper.value.classList.toggle("nyk-form--error", !validation.value.cv)

      validateFiles()
    }

    const genderUpdated = () => {
      validation.value.gender = true
      if (submitAttempted.value) {
        handleInputUpdated()
      }
    }

    const cvValidation = () => {
      const valid =
        cvFileInput.value.files.length > 0 && cvFileInput.value.files[0]?.size < maxFileSizeInBytes
      validation.value.cv = valid
      if (submitAttempted.value) {
        handleInputUpdated()
      }
    }

    const handleFileUploadWithLimit = (e) => {
      fileUploaded(e)

      Array.from(e.currentTarget.files).forEach((file) => {
        if (file.size > maxFileSizeInBytes) {
          miscFilesErrorMessage.value.textContent = `${
            labels.FileUploadMaxMbErrorMessage ?? "Filen er for stor."
          } Maks. filstørrelse er ${bytesToMB(maxFileSizeInBytes)} MB.`
          miscFilesWrapper.value.classList.add("nyk-form--error")
          validation.value.oneFileOutOfRange = true
        }
        validation.value.oneFileOutOfRange = file.size > maxFileSizeInBytes
      })

      if (e.currentTarget.files.length > miscFileLimit) {
        validation.value.miscFileLimitInRange = false
      }

      if (submitAttempted.value) {
        handleInputUpdated()
      }

      validateFiles()
    }

    const handleSingleFileUpload = (e) => {
      fileUploaded(e)
      const { files } = e.currentTarget

      if (files.length == 0) { 
        validateFiles()
        return 
      }

      const file = files[0]

      cvValidation()

      if (file.size > maxFileSizeInBytes) {
        const wrapper = e.currentTarget.closest(".nyk-js--form")
        const fileErrorMessageContainer = wrapper.querySelector(".nyk-form__error-message")
        fileErrorMessageContainer.textContent = `${
          labels.FileUploadMaxMbErrorMessage ?? "Filen er for stor."
        } Maks. filstørrelse er ${bytesToMB(maxFileSizeInBytes)} MB.`
        wrapper?.classList.add("nyk-form--error")
      }
      validation.value.oneFileOutOfRange = file.size > maxFileSizeInBytes

      validateFiles()
    }

    const bytesToMB = (bytes) => {
      const megabytes = bytes / 1024 / 1024
      return megabytes.toFixed(2)
    }

    const fileRemoved = (e) => {
      const children = e.currentTarget.children
      const cvFileParent = e.currentTarget.closest(".nyk-js--cv-files")
      const wrapper = e.currentTarget.closest(".nyk-js--form")

      if (children.length === 0) {
        wrapper.classList.remove("nyk-form--error")
      }

      if (children.length === 0 && cvFileParent) {
        validation.value.cv = false
        cvFilesErrorMessage.value.textContent = labels.value.inputCvErrorMessage
        wrapper.classList.remove("nyk-form--error")

        if (submitAttempted.value) {
          handleInputUpdated()
        }
      }

      validateFiles()
    }


    const validateMimeType = (allFileUploadElements) => {
      const isValidFileType = (file) => {
        if (!file || file.type.toString().trim() == "") return false;
        const fileType = file.type;
        return acceptedMimeTypes.includes(fileType);
      };
      
      var hasInvalidFile = false

      allFileUploadElements.forEach(fileGroup => {
        const invalidFileTypes = new Set()

        if(fileGroup.files.length == 0) {
          fileGroup.wrapperElement.value.classList.remove("nyk-form--error");
          return false;
        }

        fileGroup.files.forEach(file => {
          if (!isValidFileType(file)) {
            validation.value.mimeType = false
            hasInvalidFile = true
            invalidFileTypes.add(file.name.split('.').pop())

            const multipleFiles = invalidFileTypes.size > 1;
            fileGroup.errorMessageElement.value.textContent = `${multipleFiles ? "Filtyperne" : "Filtypen"} '${Array.from(invalidFileTypes).join(', ')}' er ikke tilladt. Tilladte filtyper er '${acceptedFileTypes.toString()}'.`;
            fileGroup.wrapperElement.value.classList.add("nyk-form--error");
          }
        });
      });

      if(!hasInvalidFile) {
        return true
      }

      return false
    }

    const validateAccumulatedTotalFileSize = (allFileUploadElements) => {
      const initialValue = 0;
      var totalFileSizes = 0
      allFileUploadElements.forEach(fileGroup => {
        
        totalFileSizes += fileGroup.files.reduce(
          (accumulator, currentValue) => accumulator + currentValue.size,
          initialValue
        )
      });

      const isWithinRange = totalFileSizes < maxAccumulatedFileSizeInBytes
      
      const errorMessage = `${labels.uploadMaxMbErrorMessage ??
        "Den samlede størrelse på filerne overstiger den tilladte størrelse."
        } Maks. samlet størrelse er ${bytesToMB(maxAccumulatedFileSizeInBytes)} MB.`

      allFileUploadElements.forEach(fileGroup => {
        if(fileGroup.files.length > 0) {
          fileGroup.errorMessageElement.value.textContent = errorMessage;
          fileGroup.wrapperElement.value.classList.toggle("nyk-form--error", !isWithinRange);
        }
      });

      return isWithinRange
    }

    const validateAccumulatedTotalFileCount = (allFileUploadElements) => {
      var totalFileAmount = 0;

      allFileUploadElements.forEach(fileGroup => {
        totalFileAmount += fileGroup.files.length;
      });

      if(totalFileAmount > miscFileLimit) {
        generalErrorMessage.value.textContent = `Du har forsøgt at uploade flere filer end tilladt for typen af fil. Du kan maks. uploade ${miscFileLimit} filer.`
        generalErrorWrapper.value.classList.add("nyk-form--error")
        return false
      }

      generalErrorWrapper.value.classList.remove("nyk-form--error")
      return true
    }

    const validateFiles = () => {
      const cvFiles = Array.from(cvFileInput.value.files)
      const applicationFiles = Array.from(applicationFileInput.value.files)
      const miscFiles = Array.from(miscFilesInput.value.files)

      const allFileUploadElements = [
        { files: cvFiles, errorMessageElement: cvFilesErrorMessage, wrapperElement: cvFileWrapper },
        { files: applicationFiles, errorMessageElement: applicationFilesErrorMessage, wrapperElement: applicationFilesWrapper },
        { files: miscFiles, errorMessageElement: miscFilesErrorMessage, wrapperElement: miscFilesWrapper }
      ];

      // Validate total file size
      validation.value.totalFileSizeInRange = validateAccumulatedTotalFileSize(allFileUploadElements)

      // Validate file MIME types
      validation.value.mimeType = validateMimeType(allFileUploadElements)

      // Validate the amount of files
      validation.value.totalNumberOfFilesInRange = validateAccumulatedTotalFileCount(allFileUploadElements)
    }

    const isFormValid = () => {
      let response = true
      if (
        !validation.value.firstName ||
        !validation.value.lastName ||
        !validation.value.address ||
        !validation.value.postalCode ||
        !validation.value.city ||
        !validation.value.email ||
        !validation.value.phone ||
        !validation.value.gender ||
        !validation.value.cv ||
        !validation.value.totalFileSizeInRange ||
        !validation.value.miscFileLimitInRange ||
        !validation.value.totalNumberOfFilesInRange ||
        validation.value.oneFileOutOfRange ||
        !validation.value.mimeType
      ) {
        response = false
      }
      return response
    }

    const handleSubmitJobApplication = async (e) => {
      submitAttempted.value = true
      handleInputUpdated()

      if (isFormValid()) {
        loading.value = true
        const response = await submitJobApplication(
          e.currentTarget,
          props.jobPostingId,
          props.apiUrl
        )

        // Simulate loading,
        // can be removed if not want forced loading animation
        setTimeout(() => {
          loading.value = false
          if (response?.status === "success") {
            submitSuccess.value = true
            setTimeout(() => {
              window.scrollTo(0,0)
            }, 300)
          } else {
            submitSuccess.value = false
            formFailedErrorMessage.value =
              response?.message ?? "Der skete en fejl. Prøv igen senere."
          }
        }, 1000)
      }
    }

    return {
      labels,
      jobApplicationRef,
      formRef,
      firstNameModel,
      lastNameModel,
      addressModel,
      postalCodeModel,
      cityModel,
      emailModel,
      phoneModel,
      firstNameWrapper,
      lastNameWrapper,
      addressWrapper,
      postalCodeWrapper,
      cityWrapper,
      emailWrapper,
      phoneWrapper,
      genderWrapper,
      cvFileWrapper,
      miscFilesWrapper,
      applicationFilesWrapper,
      generalErrorMessage,
      generalErrorWrapper,
      cvUploadedFiles,
      miscFilesInput,
      cvFileInput,
      applicationFileInput,
      emailErrorMessage,
      cvFilesErrorMessage,
      miscFilesErrorMessage,
      applicationFilesErrorMessage,
      acceptedFileTypes,
      formFailedErrorMessage,
      submitSuccess,
      loading,
      resetInputValue,
      handleFileUploadWithLimit,
      handleSingleFileUpload,
      handleSubmitJobApplication,
      genderUpdated,
      fileRemoved,
    }
  },
}
