import mapNewLoanUrlParams from '../js/mapNewLoanUrlParams'

export default async function (data, path, id) {
    const params = mapNewLoanUrlParams({
        housePrice: data.housePrice,
        ownPayment: data.ownPayment,
        housingType: data.housingType,
        mortgageLoanInterestOnlyLength: data.mortgageLoanInterestOnlyLength,
        mortgageLoanTenureLength: data.mortgageLoanTenureLength,
        bankLoanTenureLength: data.bankLoanTenureLength,
        productType: data.loanType,
    })

    const url = `${document.location.origin}/${path + params + '&id=' + id}`
    const response = await fetch(url)

    return response.json()
}
