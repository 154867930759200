import {createApp} from 'vue'
import options from './MitNykreditMenu.options'

const el = '.nyk-component--mit-nykredit-menu'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    DOM.forEach((MitNykreditMenuDOM)=>{
        const props = {
            overlayId: MitNykreditMenuDOM.dataset.overlayId
        }

        const template = MitNykreditMenuDOM.innerHTML
        options.template = template
        const component = createApp(options, props).use(Store)
        component.mount(MitNykreditMenuDOM)
    })
}

