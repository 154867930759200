const mapCategoriesToMultiSelectOptions = function(categories) {
    return categories.map(category => {
        return {
            value: category.Id, 
            name: category.Name,
            selected: false
        }
    })
}

export default mapCategoriesToMultiSelectOptions