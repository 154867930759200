export default function (value) {
    if (value === null || value === undefined) return ''
    value = value.toString()
    value = value.replace(/[^0-9]/g, '') //remove all non-digit chars
    if (value != '') {
        value = Number(value).toString() //remove prepended zeros
    }
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.') //insert thousand seperator.
    return value
}
