import { ref } from 'vue'
import { useStore } from 'vuex'
import CalculatorTable from '../components/CalculatorTable/CalculatorTable.vue'
import CalculatorSummary from '../components/CalculatorSummary/CalculatorSummary.vue'
import CalculatorInfoBox from '../components/CalculatorInfoBox/CalculatorInfoBox.vue'
import Modal from '../components/Modal/Modal.vue'
import mapNewLoanUrlParams from '../js/mapNewLoanUrlParams'
import { getTableHeaders, getTableRows } from './mapTableData'
import { openAdjustLoanModel, openSwitchLoanTypeModal } from './handleCalcModals'
import apiGetCalculatorData from './apiGetCalculatorData'

export default {
    name: 'NewLoadCalculatorResult',
    components: {
        CalculatorTable,
        CalculatorSummary,
        CalculatorInfoBox,
        Modal,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        apiUrl: {
            type: String,
            required: true,
        },
        blockId: {
            type: String,
            required: true,
        },
        tableGroups: {
            type: Array,
            required: true,
            default: [],
        },
        housingTypes: {
            type: Array,
            required: true,
            default: [],
        },
        initData: {
            type: Object,
            required: true,
        },
        summary: {
            type: Object,
            required: true,
        },
        infoBox: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore()

        // DATA FOR RENDERING
        const infoBox = ref({ ...props.infoBox })
        const summaries = ref([{ ...props.summary }])
        const tables = ref([{ ...props.tableGroups }])
        const calculationData = ref([{ ...props.initData }])

        const getLoanTypesForSummary = (index) => {
            const currentType = props.housingTypes.find((item) => item.keyName === calculationData.value[index].housingType)
            return currentType?.loanTypes ?? []
        }

        // SET CALCULATION DATA
        const setCalculationData = (data, index) => {
            if (data.loanType) calculationData.value[index].loanType = data.loanType
            if (data.housePrice) calculationData.value[index].housePrice = parseFloat(data.housePrice)
            if (data.ownPayment) calculationData.value[index].ownPayment = parseFloat(data.ownPayment)
            if (data.housingType) calculationData.value[index].housingType = data.housingType
            if (data.mortgageLoanInterestOnlyLength)
                calculationData.value[index].mortgageLoanInterestOnlyLength = parseFloat(data.mortgageLoanInterestOnlyLength)
            if (data.mortgageLoanTenureLength)
                calculationData.value[index].mortgageLoanTenureLength = parseFloat(data.mortgageLoanTenureLength)
            if (data.bankLoanTenureLength) calculationData.value[index].bankLoanTenureLength = parseFloat(data.bankLoanTenureLength)
        }

        const redirect = () => {
            const rootPath = window.location.href.replace(window.location.search, '')
            const params = mapNewLoanUrlParams({
                housePrice: calculationData.value[0].housePrice,
                ownPayment: calculationData.value[0].ownPayment,
                housingType: calculationData.value[0].housingType,
                mortgageLoanInterestOnlyLength: calculationData.value[0].mortgageLoanInterestOnlyLength,
                mortgageLoanTenureLength: calculationData.value[0].mortgageLoanTenureLength,
                bankLoanTenureLength: calculationData.value[0].bankLoanTenureLength,
                productType: calculationData.value[0].loanType,
            })
            window.location.href = rootPath + params
        }

        // HANDLE MODALS
        // SwitchLoanTypeContent
        const switchLoanTypeProps = (index, callback) => ({
            currentHousingType: getLoanTypesForSummary(index),
            labels: props.labels,
            index: index,
            selectedLoanType: calculationData.value[index].loanType,
            onCallback: callback,
        })

        const switchLoanType = function (index) {
            const handleCallback = async function (loanType, currentIndex) {
                setCalculationData({ loanType }, currentIndex)
                const response = await apiGetCalculatorData(calculationData.value[currentIndex], props.apiUrl, props.blockId)

                summaries.value[currentIndex] = { ...response.summary }
                tables.value[currentIndex] = { ...response.tableGroups }
                calculationData.value[currentIndex] = { ...response.initData }

                if (currentIndex === 0) {
                    infoBox.value = { ...response.infoBox }
                }

                store.dispatch('modal/setOpen', { open: false })
            }

            openSwitchLoanTypeModal(store, switchLoanTypeProps(index, handleCallback), props.labels.loanTypesTitle)
        }

        const switchLoanTypeWithRedirect = function (index) {
            const handleCallback = function (loanType, currentIndex) {
                setCalculationData({ loanType }, currentIndex)
                setTimeout(() => redirect(), 50)
            }

            openSwitchLoanTypeModal(store, switchLoanTypeProps(index, handleCallback), props.labels.loanTypesTitle)
        }

        // AdjustLoanContent
        // TODO: housingTypes is maybe too hardcoded here, not sure if it works for the compare
        const adjustLoanProps = (index, callback) => ({
            labels: props.labels,
            housingTypes: props.housingTypes,
            index: index,
            initData: calculationData.value[index],
            onCallback: callback,
        })

        const adjustLoan = function (index) {
            const handleCallback = async function (data, currentIndex) {
                setCalculationData(data, currentIndex)
                const response = await apiGetCalculatorData(calculationData.value[currentIndex], props.apiUrl, props.blockId)

                summaries.value[currentIndex] = { ...response.summary }
                tables.value[currentIndex] = { ...response.tableGroups }
                calculationData.value[currentIndex] = { ...response.initData }

                if (currentIndex === 0) {
                    infoBox.value = { ...response.infoBox }
                }

                store.dispatch('modal/setOpen', { open: false })
            }

            openAdjustLoanModel(store, adjustLoanProps(index, handleCallback), props.labels.adjustLoanModalTitle)
        }

        const adjustLoanWithRedirect = function (index) {
            const handleCallback = function (data) {
                setCalculationData(data, 0)
                setTimeout(() => redirect(), 50)
            }

            openAdjustLoanModel(store, adjustLoanProps(index, handleCallback), props.labels.adjustLoanModalTitle)
        }

        const openCompareLoanModal = function () {
            const handleCallback = async function (loanType, currentIndex) {
                const currentCalculationDataClone = { ...calculationData.value[currentIndex] }
                currentCalculationDataClone.loanType = loanType // set new loanType
                const response = await apiGetCalculatorData(currentCalculationDataClone, props.apiUrl, props.blockId)

                summaries.value.push({ ...response.summary })
                tables.value.push({ ...response.tableGroups })
                calculationData.value.push({ ...response.initData })

                console.log(calculationData.value)

                store.dispatch('modal/setOpen', { open: false })
            }

            openSwitchLoanTypeModal(store, switchLoanTypeProps(0, handleCallback), props.labels.loanTypesTitle)
        }

        const openSendCalculationsModal = function () {}

        const quitCompareMode = function () {
            summaries.value.pop()
            tables.value.pop()
            calculationData.value.pop()
        }

        return {
            infoBox,
            summaries,
            tables,
            getLoanTypesForSummary,
            getTableHeaders,
            getTableRows,
            switchLoanType,
            switchLoanTypeWithRedirect,
            openSendCalculationsModal,
            openCompareLoanModal,
            adjustLoan,
            adjustLoanWithRedirect,
            quitCompareMode,
        }
    },
}
