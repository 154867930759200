<script>
import options from './CalculatorSummary.options'
export default options
</script>

<template>
    <article class="nyk-calculator-summary" :class="{ 'nyk-calculator-summary--simple': !hasGraph }">
        <div class="nyk-calculator-summary__left">
            <div class="nyk-calculator-summary__left-header">
                <h2 class="nyk-calculator-summary__left-title">{{ data.title }}</h2>
                <p class="nyk-calculator-summary__left-description">{{ data.description }}</p>
            </div>
            <button v-on:click="onCallback" aria-haspopup="true" v-if="hasHousingTypes" class="nyk-button nyk-button--outline-primary">
                {{ data.buttonLabel }}
            </button>

            <dl v-if="hasGraph" class="nyk-calculator-summary__left-info">
                <div class="nyk-calculator-summary__left-info-group" v-if="data.mortgageLoanValue > 0">
                    <dt>{{ data.mortgageLoanLabel }}</dt>
                    <dd>{{ applyThousandSeperator(data.mortgageLoanValue) }}</dd>
                </div>
                <div class="nyk-calculator-summary__left-info-group" v-if="data.bankLoanValue > 0">
                    <dt>{{ data.bankLoanLabel }}</dt>
                    <dd>{{ applyThousandSeperator(data.bankLoanValue) }}</dd>
                </div>
            </dl>
        </div>

        <div v-if="hasGraph" class="nyk-calculator-summary__right">
            <figure class="nyk-calculator-summary__right-svg-container">
                <svg role="presentation" viewBox="0 0 36 36" class="nyk-calculator-summary__svg">
                    <path
                        class="nyk-calculator-summary__svg-circle"
                        stroke-width="1.4"
                        stroke="#0F1E82"
                        stroke-dasharray="100, 100"
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                    <path
                        class="nyk-calculator-summary__svg-circle"
                        stroke-width="1.4"
                        stroke="#4192DC"
                        :stroke-dasharray="`${mortgageLoanProcents}, 100`"
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                </svg>
                <div class="nyk-calculator-summary__svg-text">
                    <dl>
                        <dt class="nyk-calculator-summary__svg-text-label">{{ data.totalMonthlyPaymentBeforeTax }}</dt>
                        <dd class="nyk-calculator-summary__svg-text-value">{{ data.totalMonthlyPaymentBeforeTaxValue }}</dd>
                        <dt class="nyk-calculator-summary__svg-text-label">{{ data.totalMonthlyPaymentAfterTax }}</dt>
                        <dd class="nyk-calculator-summary__svg-text-value-small">{{ data.totalMonthlyPaymentAfterTaxValue }}</dd>
                    </dl>
                </div>
            </figure>
        </div>

        <div v-else>
            <dl class="nyk-calculator-summary__definiftion">
                <div class="nyk-calculator-summary__definiftion-group">
                    <dt class="nyk-calculator-summary__definiftion-label">{{ data.totalMonthlyPaymentBeforeTax }}</dt>
                    <dd class="nyk-calculator-summary__definiftion-value">
                        {{ data.totalMonthlyPaymentBeforeTaxValue }}
                        <span class="nyk-calculator-summary__definiftion-value-prefix">{{ labels.currencyPrefix }}</span>
                    </dd>
                </div>
                <div class="nyk-calculator-summary__definiftion-group">
                    <dt class="nyk-calculator-summary__definiftion-label">{{ data.totalMonthlyPaymentAfterTax }}</dt>
                    <dd class="nyk-calculator-summary__definiftion-value-small">
                        {{ data.totalMonthlyPaymentAfterTaxValue }}
                        <span class="nyk-calculator-summary__definiftion-value-prefix">{{ labels.currencyPrefix }}</span>
                    </dd>
                </div>
            </dl>
        </div>
    </article>
</template>
