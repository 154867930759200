import { createApp } from "vue"
import MultiSelectDropdown from "./MultiSelectDropdown"

const el = ".nyk-component--multi-select-dropdown"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((MultiSelectDropdownDOM) => {
      const props = {
        initOptions: MultiSelectDropdownDOM.dataset.initOptions
          ? JSON.parse(MultiSelectDropdownDOM.dataset.initOptions)
          : [],
        title: MultiSelectDropdownDOM.dataset.title ?? "",
        placeholderLabel: MultiSelectDropdownDOM.dataset.placeholderLabel ?? "",
        onChangeCallback:
          MultiSelectDropdownDOM.dataset.onChangeCallback ?? null,
      }
      const component = createApp(MultiSelectDropdown, props).use(Store)
      component.mount(MultiSelectDropdownDOM)
    })
  }
}
