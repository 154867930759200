import { ref, onMounted } from "vue"
import _signupStepsCommonProps from "../../foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../../../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepActions/SignupStepActions.vue"
import { cprFormatting } from "../../foundation/js/form-methods.js"

export default {
  name: "ContactStepContent",
  props: _signupStepsCommonProps,
  components: {
    SignupStepActions,
  },
  setup(props) {
    const firstNameInput = ref(props.getNamedFieldInputData.firstname)
    const lastNameInput = ref(props.getNamedFieldInputData.lastname)
    const emailInput = ref(props.getNamedFieldInputData.email)
    const phoneInput = ref(props.getNamedFieldInputData.phone)
    const isCustomerInput = ref(props.getNamedFieldInputData.isCustomer)
    const showCPR = ref(props.getNamedFieldInputData.isCustomer ?? false)
    const cprInput = ref(props.getNamedFieldInputData.cpr)
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })

    const handleIsCustomerInput = (bool) => {
      isCustomerInput.value = bool
      showCPR.value = bool
    }

    return {
      firstNameInput,
      lastNameInput,
      emailInput,
      phoneInput,
      isCustomerInput,
      handleIsCustomerInput,
      showCPR,
      cprInput,
      cprFormatting,
      contentClassnames,
    }
  },
}
