export const submitJobApplication = async (form, jobId, apiUrl) => {
  const formData = new FormData()
  const elements = form.elements

  const selectedGender = Array.from(elements.gender).find((gender) => gender.checked === true)

  formData.append("FirstName", elements.firstname.value)
  formData.append("LastName", elements.lastname.value)
  formData.append("Address", elements.address.value)
  formData.append("Zipcode", elements.postalcode.value)
  formData.append("City", elements.city.value)
  formData.append("Email", elements.email.value)
  formData.append("Phone", elements.phone.value)
  formData.append("JobReference", jobId)
  formData.append("Gender", selectedGender.value)

  if (elements.cvfile.files.length > 0) {
    Array.from(elements.cvfile.files).forEach((file) => {
      formData.append("CvFiles", file)
    })
  }

  if (elements.applicationfile.files.length > 0) {
    Array.from(elements.applicationfile.files).forEach((file) => {
      formData.append("CoverLetterFiles", file)
    })
  }

  if (elements.miscfiles.files.length > 0) {
    Array.from(elements.miscfiles.files).forEach((file) => {
      formData.append("MiscFiles", file)
    })
  }

  const response = await postData(apiUrl, formData)
  return response
}

async function postData(url, data) {
  
  return await fetch(url, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {        
          throw new Error("Der skete en fejl. Pr�v igen senere.")
      }
    })
    .then((json) => {
      return json; 
    })
    .catch((err) => {
      console.error(err)
      return err
    })
}
