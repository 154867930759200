import { getJobPostingsRows } from "../../components/JobPostingsTable/JobPostingsTable.options.adapter"

export default {
  namespaced: true,
  state: {
    headers: [],
    rows: [],
    filters: [],
    redirectUrl: "",
  },
  mutations: {
    set_headers(state, value) {
      state.headers = value
    },
    set_rows(state, value) {
      state.rows = value
    },
    set_filters(state, value) {
      state.filters = value
    },
    set_filtered_rows(state, value) {
      state.rows = value
    },
    set_redirect_url(state, value) {
      state.redirectUrl = value
    },
  },
  actions: {
    headers(context, config) {
      context.commit("set_headers", config.headers)
    },
    rows(context, config) {
      context.commit("set_rows", config.rows)
    },
    filters(context, config) {
      context.commit("set_filters", config.filters)
    },
    redirectUrl(context, config) {
      context.commit("set_redirect_url", config.url)
    },
    filteredRows(context, config) {
      // TODO: use api

      let url = `${config.api}?type=${config.filter}`

      const request = new Request(url)

      fetch(request)
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error("Didn't work")
          }
        })
        .then((json) => {
          const rows = getJobPostingsRows(json)
          context.commit("set_rows", rows)
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
