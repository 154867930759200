import { createApp } from 'vue'
import FormRadioGroupInput from './FormRadioGroupInput'
import handleComponentProps from '../../../../../../../Frontend/src/foundation/js/utils/handleComponentProps.js'

const el = '.nyk-component--form-radio-input'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((FormRadioGroupInputDOM) => {
            const props = handleComponentProps(FormRadioGroupInput, FormRadioGroupInputDOM)
            const component = createApp(FormRadioGroupInput, props).use(Store)
            component.mount(FormRadioGroupInputDOM)
        })
    }
}
