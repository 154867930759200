<script>
import options from "./SignupSingleNewsletter.options"
export default options
</script>

<template>
  <div
    class="nyk-signup-single-newsletter"
    :class="`nyk-signup-single-newsletter--${newletterClassName}`">
    <component
      :is="currentStepName"
      :nextStepCallback="validateInputAndProceed"
      :previousStepCallback="returnToPreviousStep"
      :displayInputError="displayInputError"
      :currentStepTexts="currentStepTexts"
      :hasPreviousStep="hasPreviousStep"
      :resetSignupForm="resetSignupForm"
      :getNamedFieldInputData="getNamedFieldInputData"
      :showFieldErrorMessage="showFieldErrorMessage"
      :stepCounterText="stepCounterText" />
  </div>
</template>
