import { createApp } from "vue"
import AnnualReportsTable from "./AnnualReportsTable"

const el = ".nyk-component--annual-reports-table"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((AnnualReportsTableDOM) => {
      const props = {
        groups: JSON.parse(AnnualReportsTableDOM.dataset.groups),
        reports: JSON.parse(AnnualReportsTableDOM.dataset.reports),
        title: AnnualReportsTableDOM.dataset.title,
        subtitle: AnnualReportsTableDOM.dataset.subtitle
      }
      const component = createApp(AnnualReportsTable, props).use(Store)
      component.mount(AnnualReportsTableDOM)
    })
  }
}
