

export default {
    namespaced: true,
    state: {
        otherSitesDropdownShown: false,
        languageDropdownShown: false,
        submenuOpen: false,
        topLevelPagesDropdownShown: false,
        searchOverlayShown: false,
        searchOverlayMobileNavigateBack: false,
        activeSubmenuDesktop: ""
    },
    mutations: {
        set_other_sites_dropdown_shown(state, value) {
            state.otherSitesDropdownShown = value
        },
        set_language_dropdown_shown(state, value) {
            state.languageDropdownShown = value
        },
        set_submenu_open(state, value) {
            state.submenuOpen = value
        },
        set_top_level_pages_dropdown_shown(state, value) {
            state.topLevelPagesDropdownShown = value
        },
        set_search_overlay_shown(state, value) {
            state.searchOverlayShown = value
        },
        set_search_overlay_mobile_navigate_back(state, value) {
            state.searchOverlayMobileNavigateBack = value
        },
        set_active_submenu(state, value) {
            state.activeSubmenuDesktop = value
        }
    },
    actions: {
        otherSitesDropdownShown(context, config) {
            context.commit('set_other_sites_dropdown_shown', config.open)
        },
        languageDropdownShown(context, config) {
            context.commit('set_language_dropdown_shown', config.open)
        },
        submenuOpen(context, config) {
            context.commit('set_submenu_open', config.open)
        },
        topLevelPagesDropdownShown(context, config) {
            context.commit('set_top_level_pages_dropdown_shown', config.open)
        },
        searchOverlayShown(context, config) {
            context.commit('set_search_overlay_shown', config.open)
        },
        searchOverlayMobileNavigateBack(context, config) {
            context.commit('set_search_overlay_mobile_navigate_back', config.navigate)
        },
        activeSubmenuDesktop(context, config) {
            context.commit('set_active_submenu', config.menu)
        }
    }
}