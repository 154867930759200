import { createApp } from "vue"
import options from "./FeedbackBlock.options"

const el = ".nyk-component--feedback-block"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  DOM.forEach((FeedbackBlockDOM) => {
    const props = {
      apiUrlStepOne: FeedbackBlockDOM.dataset.apiUrlStepOne ?? "",
      apiUrlStepTwo: FeedbackBlockDOM.dataset.apiUrlStepTwo ?? "",
      identifier: FeedbackBlockDOM.dataset.identifier ?? "",
    }
    const template = FeedbackBlockDOM.outerHTML
    options.template = template
    const component = createApp(options, props).use(Store)
    component.mount(FeedbackBlockDOM)
  })
}
