import { ref, onMounted } from "vue"

export default {
  name: "FeedbackStepOne",
  props: {
    title: {
      type: String,
      required: true,
    },
    actions: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const options = ref([])

    onMounted(() => {
      if (props.actions) {
        options.value = JSON.parse(props.actions)
      }
    })

    const inputSelected = (e) => {
      if (props.callback) {
        props.callback(e.target.value)
      }
    }

    return {
      inputSelected,
      options,
    }
  },
}
