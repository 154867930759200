import {createApp} from 'vue'
import DynamicTable from './DynamicTable'

const el = '.nyk-component--dynamic-table'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((DynamicTableDOM) => {
            const component = createApp(DynamicTable).use(Store)
            component.mount(DynamicTableDOM)
        })
    }
}