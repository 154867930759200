import { createApp } from "vue"
import JobPostingsTable from "./JobPostingsTable"
import {
  getJobPostingsHeader,
  getJobPostingsRows,
  getJobPostingsNumberOfResults,
  getJobPostingsFilters,
} from "./JobPostingsTable.options.adapter"

const el = ".nyk-component--job-postings-table"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((JobPostingsTableDOM) => {
      const model = JSON.parse(JobPostingsTableDOM.dataset.model)
      const props = {
        numberOfResults: model.data
          ? getJobPostingsNumberOfResults(model.data)
          : 0,
        apiUrl: JobPostingsTableDOM.dataset.apiUrl,
      }

      if (model) {
        Store.dispatch("jobs/redirectUrl", { url: model.data.jobPageUrl })
        Store.dispatch("jobs/headers", {
          headers: getJobPostingsHeader(model.data),
        })
        Store.dispatch("jobs/rows", {
          rows: getJobPostingsRows(model.data.jobPostingsList),
        })
        Store.dispatch("jobs/filters", {
          filters: getJobPostingsFilters(model.data),
        })
      }

      const component = createApp(JobPostingsTable, props).use(Store)
      component.mount(JobPostingsTableDOM)
    })
  }
}
