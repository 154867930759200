import { createApp } from "vue"
import SignupStepActions from "./SignupStepActions"

const el = ".nyk-component--signup-step-actions"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((SignupStepActionsDOM) => {
      const props = {
        previousStepCallback: SignupStepActionsDOM.dataset.previousStepCallback,
        nextStepCallback: SignupStepActionsDOM.dataset.nextStepCallback,
        nextStepButtonText: SignupStepActionsDOM.dataset.nextStepButtonText,
        stepCounterText: SignupStepActionsDOM.dataset.stepCounterText,
        hasPreviousStep: SignupStepActionsDOM.dataset.hasPreviousStep,
      }
      const component = createApp(SignupStepActions, props).use(Store)
      component.mount(SignupStepActionsDOM)
    })
  }
}
