import {createApp} from 'vue'
import options from './VideoBlock.options'

const el = '.nyk-component--video-block'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    DOM.forEach((VideoBlockDOM)=>{
        const template = VideoBlockDOM.outerHTML
        options.template = template
        const props = {
            videoBlock: VideoBlockDOM
        }

        const component = createApp(options, props).use(Store)
        component.mount(VideoBlockDOM)
    })
}
