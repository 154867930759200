import { createApp } from "vue"
import JobApplication from "./JobApplication"

const el = ".nyk-component--job-application"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((JobApplicationDOM) => {
      const props = {
        apiUrl: JobApplicationDOM.dataset.apiUrl,
        jobPostingId: JobApplicationDOM.dataset.jobPostingId,
        genderList: JobApplicationDOM.dataset.genderList
          ? JSON.parse(JobApplicationDOM.dataset.genderList)
          : null,
        initLabels: JobApplicationDOM.dataset.initLabels
          ? JSON.parse(JobApplicationDOM.dataset.initLabels)
          : null,
        title: JobApplicationDOM.dataset.title,
        description: JobApplicationDOM.dataset.description,
        subject: JobApplicationDOM.dataset.subject,
        successLabels: JobApplicationDOM.dataset.successLabels
          ? JSON.parse(JobApplicationDOM.dataset.successLabels)
          : null,
      }
      const component = createApp(JobApplication, props).use(Store)
      component.mount(JobApplicationDOM)
    })
  }
}
