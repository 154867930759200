import {createApp} from 'vue'
import options from './ContextBanner.options'

const el = '.nyk-component--context-banner'

export default function(Store) {
    const DOM = document.querySelectorAll(el)
    DOM.forEach((ContextBannerDOM) => {
        const props = {
            bannerId: ContextBannerDOM.dataset.id
        }
        const template = ContextBannerDOM.outerHTML
        options.template = template
        const component = createApp(options, props).use(Store)
        component.mount(ContextBannerDOM)
    })
}

