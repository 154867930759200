export default function (event, dialogElement) {
    const focusableElements = getFocusableElements(dialogElement)
    const firstElement = focusableElements[0]
    const lastElement = focusableElements[focusableElements.length - 1]

    if (event.key === 'Tab') {
        if (event.shiftKey) {
            // If Shift + Tab is pressed and focus is on the first element
            if (document.activeElement === firstElement) {
                event.preventDefault()
                lastElement.focus() // Focus on the last element
            }
        } else {
            // If Tab is pressed and focus is on the last element
            if (document.activeElement === lastElement) {
                event.preventDefault()
                firstElement.focus() // Focus on the first element
            }
        }
    }
}

// Function to check if an element is visible and focusable
function isElementFocusable(el) {
    const isFocusable = focusableElementsTypes.includes(el.tagName.toLowerCase())

    // Check if the element is focusable by size, disabled state, and visibility
    const isVisible = el.offsetWidth > 0 && el.offsetHeight > 0 && el.checkVisibility()
    const isNotDisabled = !el.disabled

    return isFocusable && isVisible && isNotDisabled
}

// Function to get focusable elements by traversing the DOM
function getFocusableElements(container) {
    const focusableElements = []

    function traverse(element) {
        if (isElementFocusable(element)) {
            focusableElements.push(element)
        }

        // Traverse the children recursively
        for (let child of element.children) {
            traverse(child)
        }
    }

    // Start traversal from the container
    traverse(container)
    return focusableElements
}

const focusableElementsTypes = ['a', 'summary', 'button', 'input', 'select', 'textarea']
