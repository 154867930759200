import { createApp } from "vue"
import CenterMap from "./CenterMap.vue"

const el = ".nyk-component--center-map"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((CenterMapDOM) => {
      const props = {
        mapkey: CenterMapDOM.dataset.mapkey,
        categories: JSON.parse(CenterMapDOM.dataset.categories),
        labels: JSON.parse(CenterMapDOM.dataset.labels),
        centerInformation: CenterMapDOM.dataset.centerInformation ? JSON.parse(CenterMapDOM.dataset.centerInformation) : null,
      }
      const component = createApp(CenterMap, props).use(Store)
      component.mount(CenterMapDOM)
    })
  }
}
