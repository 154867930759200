export default {
  currentStepTexts: {
    type: Object,
    required: true,
  },
  nextStepCallback: {
    type: Function,
    required: true,
  },
  previousStepCallback: {
    type: Function,
    required: false,
  },
  showFieldErrorMessage: {
    type: Function,
    required: true,
  },
  getNamedFieldInputData: {
    type: Object,
    required: true,
  },
  newslettersData: {
    type: Array,
    required: false,
  },
  nextStepButtonText: {
    type: String,
    required: false,
    default: "Videre",
  },
  resetSignupForm: {
    type: Function,
    required: true,
  },
  imageSource: {
    type: String,
    required: false,
  },
  imageAltText: {
    type: String,
    required: false,
  },
  // this should be the output of a computed value in the parent
  stepCounterText: {
    type: String,
    required: true,
  },
  labels: {
    type: Object,
    required: false,
  },
  hasPreviousStep: {
    type: Boolean,
    required: false,
    default: false,
  },
  displayInputError: {
    type: Boolean,
    required: false,
    default: false,
  },
}
