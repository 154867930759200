<script>
import options from "./FeedbackStepTwo.options"
export default options
</script>

<template>
    <article class="nyk-feedback-step-two">
        <div class="nyk-feedback-step-two__top">
            <h2 class="nyk-feedback-step-two__top__title">{{ title }}</h2>
            <div class="nyk-feedback-step-two__top__description">{{ description }}</div>
        </div>

        <div class="nyk-feedback-step-two__bottom">
            <div class="nyk-feedback-step-two__bottom__text">
                <div
                    role="textbox"
                    contenteditable
                    v-on:keyup="feedbackTextUpdated"
                    class="nyk-feedback-step-two__bottom__text__output"
                    :data-placeholder="placeholder"></div>
            </div>

            <div class="nyk-feedback-step-two__bottom__actions">
                <div class="nyk-feedback-step-two__bottom__actions__action">
                    <span class="nyk-feedback-step-two__bottom__actions__action__text">{{ buttonText }}</span>
                    <button
                        v-on:click="submitFeedback"
                        class="nyk-feedback-step-two__bottom__actions__action__button nyk-button nyk-button--link-circle-arrow right">
                        <div class="nyk-button__icon">
                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                <path
                                    fill="#07094A"
                                    d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </article>
</template>
