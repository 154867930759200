const updateBounds = function (props, map, mapLocations, defaultZoom) {

    if (mapLocations.value === null){
        return
    }

    const maxZoom = 15

    const bounds = new window.google.maps.LatLngBounds();
    if (props.focusPoints.length > 0) {
        props.focusPoints.forEach((focusPoint) => {
            const point = new window.google.maps.LatLng(focusPoint.Latitude, focusPoint.Longitude);
            bounds.extend(point);
        });
        map.value.fitBounds(bounds);
        map.value.setZoom(Math.min(map.value.getZoom(), maxZoom));
    }
    else {
        //bounds.extend(defaultCenter.value);    
        map.value.fitBounds(bounds);
        map.value.setZoom(defaultZoom);
    }
}

export default updateBounds