import { computed, ref } from "vue"
import debounce from "../../foundation/js/debounce"

export default {
  name: "SearchInputField",
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    initSearchParam: {
      type: String,
      required: false,
    },
    onChangeCallback: {
      type: Function,
      required: false,
    },
    onEnterCallback: {
      type: Function,
      required: false,
    },
    redirectUrl: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const searchInputModel = ref(props.initSearchParam ?? "")
    const searchInput = ref("")

    const inputUpdated = debounce(() => {
      if (props.onChangeCallback) {
        props.onChangeCallback(searchInputModel.value)
      }
    }, 500)

    const inputIsNotEmpty = computed(() => {
      return searchInputModel.value !== ""
    })

    const emptyInput = () => {
      searchInputModel.value = ""
      searchInput.value.focus()

      if (props.onChangeCallback) {
        props.onChangeCallback(searchInputModel.value)
      }
    }

    const onSearchEnter = () => {
      if (props.redirectUrl) {
        window.location.href = `${props.redirectUrl}?q=${searchInputModel.value}`
      } else if (props.onEnterCallback) {
        props.onEnterCallback(searchInputModel.value)
      }
    }

    return {
      searchInputModel,
      searchInput,
      emptyInput,
      onSearchEnter,
      inputIsNotEmpty,
      inputUpdated,
    }
  },
}
