import { createApp } from "vue"
import DynamicDocumentList from "./DynamicDocumentList"

const el = ".nyk-component--dynamic-document-list"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((DynamicDocumentListDOM) => {
      const props = {
        title: DynamicDocumentListDOM.dataset.title
          ? DynamicDocumentListDOM.dataset.title
          : null,
        description: DynamicDocumentListDOM.dataset.description
          ? DynamicDocumentListDOM.dataset.description
          : null,
        documentLists: DynamicDocumentListDOM.dataset.documentLists
          ? JSON.parse(DynamicDocumentListDOM.dataset.documentLists)
          : null,
      }
      const component = createApp(DynamicDocumentList, props).use(Store)
      component.mount(DynamicDocumentListDOM)
    })
  }
}
