import { createApp } from "vue"
import DocumentArchive from "./DocumentArchive"

const el = ".nyk-component--document-archive"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((DocumentArchiveDOM) => {
      const props = {
        model: DocumentArchiveDOM.dataset.model
          ? JSON.parse(DocumentArchiveDOM.dataset.model)
          : null,
        apiUrl: DocumentArchiveDOM.dataset.apiUrl ?? "",
        title: DocumentArchiveDOM.dataset.title ?? "",
        header: DocumentArchiveDOM.dataset.header ?? "",
        description: DocumentArchiveDOM.dataset.description ?? "",
      }
      const component = createApp(DocumentArchive, props).use(Store)
      component.mount(DocumentArchiveDOM)
    })
  }
}
