import { ref, onMounted } from "vue"
import _sendCalculationStepsCommonProps from "../../foundation/js/configurations/_sendCalculationStepsCommonProps"
import SendCalculationStepActions from "../../../../Features/Blocks/SendCalculation/Actions/SendCalculationStepActions.vue"

export default {
  name: "SendCalculationStepContent",
    props: _sendCalculationStepsCommonProps,
  components: {
      SendCalculationStepActions,
  },
  setup(props) {
    const firstNameInput = ref(props.getNamedFieldInputData.firstname)
    const lastNameInput = ref(props.getNamedFieldInputData.lastname)
    const emailInput = ref(props.getNamedFieldInputData.email)
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })


    return {
      firstNameInput,
      lastNameInput,
      emailInput,
      contentClassnames,
    }
  },
}
