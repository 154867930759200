<script>
import options from "./AnnualReportsTable.options"
export default options
</script>

<template>
    <article class="nyk-annual-reports-table">
        <div class="nyk-annual-reports-table__header">
            <h2 class="nyk-annual-reports-table__header__title">
                {{ title }}
            </h2>
            <p class="nyk-annual-reports-table__header__description">
                {{ subtitle }}
            </p>
        </div>

        <template v-if="tabs.length > 0">
            <FilterTabs :initActiveTab="tabs[0].id" :tabs="tabs" :callback="changeFilter" />
        </template>

        <template v-if="tables.length > 0">
            <template v-for="(table, index) in tables" :key="index">
                <div
                    :aria-labelledby="table.year"
                    class="nyk-annual-reports-table__table-wrapper"
                    :class="table.year !== activeYear ? 'nyk-annual-reports-table__table-wrapper--hidden' : ''">
                    <DynamicTable :headers="table.headers" :rows="table.rows" />
                </div>
            </template>
        </template>
    </article>
</template>
