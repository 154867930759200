import { createApp } from "vue"
import KontactCard from "./ContactCardOnboarding"

const el = ".nyk-component--kontact-card-onboarding"

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((KontactCardDOM) => {
            const props = {
                labels: JSON.parse(KontactCardDOM.dataset.labels),
                formId: KontactCardDOM.dataset.formId,
                apiUrl: KontactCardDOM.dataset.apiUrl,
                newsletterApiUrl: KontactCardDOM.dataset.newsletterApiUrl,
                newsletters: KontactCardDOM.dataset.newsletters ? JSON.parse(KontactCardDOM.dataset.newsletters) : [],
                alignIntroImageRight: KontactCardDOM.dataset.alignIntroImageRight === "true",
                imageSource: KontactCardDOM.dataset.imageSource,
                backgroundColor: KontactCardDOM.dataset.backgroundColor ?? null,
                assignTo: KontactCardDOM.dataset.assignTo,
                enableUserTrack: KontactCardDOM.dataset.enableUserTrack === "true"

            }
            const component = createApp(KontactCard, props).use(Store)
            component.mount(KontactCardDOM)
        })
    }
}
