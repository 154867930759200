import {createApp} from 'vue'
import ContactStepContent from './ContactStepContent'

const el = '.nyk-component--contact-step-content'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((ContactStepContentDOM)=>{
            const component = createApp(ContactStepContent).use(Store)
            component.mount(ContactStepContentDOM)
        })
    }
}