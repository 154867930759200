<script>
  import options from './GoogleMapsWithMarkers.options'
  export default options
</script>

<template>
  <div class="nyk-google-maps-with-markers">
    <div style="width:100%; height:100%; position:relative" id="mapcontainer"></div>
  </div>
</template>
