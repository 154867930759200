<script>
import options from "./SiteSearching.options"

export default options
</script>

<template>
    <themeSwitch theme="nyk-sparinvest" />

    <article class="nyk-site-searching">
        <div class="nyk-site-searching__content">
            <div class="nyk-site-searching__content__header">
                <h1 class="nyk-site-searching__content__header__title">
                    {{ labels.searchResultTitleLabel }}
                </h1>
                <div class="nyk-site-searching__content__header__count">
                    <span class="nyk-site-searching__content__header__count__number">{{ unfilteredNumberOfResults }}</span>
                    <span class="nyk-site-searching__content__header__count__text">{{ labels.resultNumberLabel }}</span>
                </div>
            </div>

            <div class="nyk-site-searching__content__list">
                <SearchInputField :initSearchParam="searchInput" :onChangeCallback="inputChanged" />

                <template v-if="searchFacts.length > 0">
                    <div class="nyk-js--facts-container">
                        <FilterTabs :initActiveTab="activeFilter" :tabs="searchFacts" :callback="changeFilter" />
                    </div>
                </template>

                <div class="nyk-site-searching__content__list__results" aria-live="polite">
                    <template v-for="(result, index) in searchResults">
                        <SiteSearchingResult
                            :tag="result.section"
                            :href="result.url"
                            :title="result.title"
                            :description="result.excerpt"
                            :filename="result.filename" />
                    </template>
                </div>

                <template v-if="pageTotalSize > currentPage">
                    <div class="nyk-site-searching__content__list__action">
                        <button v-on:click="getMoreResults" class="nyk-button nyk-button--outline-primary">
                            {{ labels.seeMoreButtonLabel }}
                        </button>
                    </div>
                </template>
            </div>
        </div>
    </article>
</template>
