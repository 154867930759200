import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"

export default {
  name: "SignupStepActions",
  props: {
    previousStepCallback: {
      type: Function,
      required: false,
    },
    nextStepCallback: {
      type: Function,
      required: true,
    },
    nextStepButtonText: {
      type: String,
      required: true,
    },
    stepCounterText: {
      type: String,
      required: true,
    },
    hasPreviousStep: {
      type: Boolean,
      required: true,
      default: false,
    },
    spaceTop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {},
}
