import { ref } from "vue"
import FeedbackStepOne from "./FeedbackStepOne/FeedbackStepOne.vue"
import FeedbackStepTwo from "./FeedbackStepTwo/FeedbackStepTwo.vue"
import FeedbackStepThree from "./FeedbackStepThree/FeedbackStepThree.vue"

export default {
  name: "FeedbackBlock",
  components: {
    FeedbackStepOne,
    FeedbackStepTwo,
    FeedbackStepThree,
  },
  props: {
    apiUrlStepOne: {
      type: String,
      required: true,
    },
    apiUrlStepTwo: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const FeedbackSteps = Object.freeze({
      One: 1,
      Two: 2,
      Three: 3,
    })

    const currentStep = ref(FeedbackSteps.One)
    const choice = ref("")
    const message = ref("")

    const handleStepOne = async (value) => {
      choice.value = value
      const postSuccess = await submitStepOne()
      if (postSuccess) {
        currentStep.value = FeedbackSteps.Two
      } else {
        console.error("Error submitting step one")
      }
    }

    const handleStepTwo = async (value) => {
      message.value = value
      const postSuccess = await submitStepTwo()
      if (postSuccess) {
        currentStep.value = FeedbackSteps.Three
      } else {
        console.error("Error submitting step two")
      }
    }

    const handleStepThree = () => {
      currentStep.value = FeedbackSteps.One
    }

    const submitStepOne = async () => {
      const url = props.apiUrlStepOne

      const body = {
        choice: choice.value,
        identifier: props.identifier,
        url: location.href,
      }

      registerGtmDataLayer({
        event: "faq-feedback-choice",
        url: location.href,
        choice: choice.value,
      })

      return fetchData(url, body)
    }

    const submitStepTwo = async () => {
      const url = props.apiUrlStepTwo

      const body = {
        choice: choice.value,
        message: message.value,
        identifier: props.identifier,
        url: location.href,
      }

      registerGtmDataLayer({
        event: "faq-feedback-message",
        url: location.href,
        choice: choice.value,
        message: message.value,
      })

      return fetchData(url, body)
    }

    const fetchData = async (url, body) => {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
      return process.env.ENVIRONMENT === "DEVELOPMENT" ? true : response.ok
    }

    // THIS FUNCTION ALSO EXISTS IN LegacyFrontend
    function registerGtmDataLayer(obj) {
      // create datalayer if not exist
      window.dataLayer =
        typeof window.dataLayer != "undefined" && window.dataLayer instanceof Array ? window.dataLayer : []
      window.dataLayer.push(obj)
    }

    return {
      handleStepOne,
      currentStep,
      FeedbackSteps,
      handleStepOne,
      handleStepTwo,
      handleStepThree,
    }
  },
}
