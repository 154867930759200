import { createApp } from "vue"
import ContactStepOnboardingContent from "./ContactStepOnboardingContent.options"

const el = ".nyk-component--contact-step-onboarding-content"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((ContactStepInfoDOM) => {
      const component = createApp(ContactStepOnboardingContent).use(Store)
      component.mount(ContactStepInfoDOM)
    })
  }
}
