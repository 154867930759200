var uploadedFiles = []

export const resetInputValue = (e) => {
  const currentInputContainer = e.currentTarget.closest(".nyk-js--form")
  const currentInput = currentInputContainer?.querySelector("input")
  currentInput.value = ""
  currentInput.focus()
}

export const cprFormatting = (e) => {
  const input = e.target
  input.value = input.value.replace(/[^0-9]/g, "") // only numbers

  if (input.value.includes("-") && input.value.length === 7) {
    input.value = input.value.replaceAll("-", "")
  }

  if (input.value !== "" && !input.value.includes("-")) {
    input.value = input.value.match(/.{1,6}/g).join("-")
  }
}

export const fileUploaded = (e) => {
  const { files } = e.target
  const multipleUploads = e.target.hasAttribute("multiple")
  const parent = e.target.closest(".nyk-js--form")
  const fileContainer = parent?.querySelector(".nyk-js--files")

  if (multipleUploads) {
    handleMultipleUploads(files, e)
  } else {
    handleSingleUploads(files, fileContainer)
  }
}

const handleMultipleUploads = (files, e) => {
  const inputId = e.target.id
  Array.from(files).map((file) => {
    const exist = checkIfFileIsUploaded(file, inputId)
    if (!exist) {
      uploadedFiles.push({ file: file, id: inputId })
    }
  })

  renderUploadedFiles(e)
}

const handleSingleUploads = (files, container) => {
  container.innerHTML = ""
  Array.from(files).map((file) => {
    const item = createUploadedFileItem(file)
    container.appendChild(item)

    const button = item.querySelector(".nyk-js--file-remove")
    button?.addEventListener("click", (evt) => {
      const parent = evt.target.closest(".nyk-js--form")
      const input = parent?.querySelector("input")
      const container = evt.target.closest(".nyk-js--files")

      container.innerHTML = ""
      input.value = ""
    })
  })
}

const checkIfFileIsUploaded = (file, inputId) => {
  let res = false
  const filesForCurrentInput = getAllUploadedFileForInput(uploadedFiles, inputId)
  filesForCurrentInput.find((item) => {
    if (item.file.name === file.name && item.id === inputId) {
      res = true
    }
  })
  return res
}

const getAllUploadedFileForInput = function (uploadedFiles, inputId) {
  return Array.from(uploadedFiles).filter((item) => item.id === inputId)
}

const createUploadedFileItem = (file) => {
  const li = document.createElement("li")
  li.classList.add("nyk-form__files__file")

  const removeButton = document.createElement("button")
  removeButton.classList.add("nyk-form__files__file__button")
  removeButton.classList.add("nyk-js--file-remove")

  removeButton.setAttribute("data-file-name", file.name)

  const closeIcon = document.createElement("span")
  closeIcon.classList.add("nyk-icon")
  closeIcon.classList.add("nyk-icon--ui-close")

  removeButton.appendChild(closeIcon)

  const span = document.createElement("span")
  span.classList.add("nyk-form__files__file__name")
  span.innerText = file.name

  li.appendChild(removeButton)
  li.appendChild(span)

  return li
}

const renderUploadedFiles = (e) => {
  const inputId = e.target.id
  const filesForCurrentInput = getAllUploadedFileForInput(uploadedFiles, inputId)

  createAndAddFileItemsToContainer(filesForCurrentInput, e)
  addFilesToInputFileList(filesForCurrentInput, e)
}

const removeFile = (currentFileName, inputEvent) => {
  const id = inputEvent.target.id
  const files = Array.from(uploadedFiles).filter((item) => {
    if (item.id !== id || (currentFileName !== item.file.name && item.id === id)) {
      return item
    }
  })

  uploadedFiles = files
  renderUploadedFiles(inputEvent)
}

const addFilesToInputFileList = (files, e) => {
  const dt = new DataTransfer()
  files.map((file) => dt.items.add(file.file))
  e.target.files = dt.files
}

const createAndAddFileItemsToContainer = (files, e) => {
  const inputId = e.target.id
  const parent = e.target.closest(".nyk-js--form")
  const fileContainer = parent?.querySelector(".nyk-js--files")

  if (fileContainer) {
    fileContainer.innerHTML = ""

    files.map((item) => {
      if (item.id === inputId) {
        const fileItem = createUploadedFileItem(item.file)
        fileContainer.appendChild(fileItem)
        addEventListenerToRemoveFile(fileItem, e)
      }
    })
  }
}

const addEventListenerToRemoveFile = (item, e) => {
  const button = item.querySelector(".nyk-js--file-remove")
  button?.addEventListener("click", (evt) => {
    const currentFileName = evt.currentTarget.dataset.fileName
    removeFile(currentFileName, e)
  })
}
