import Tooltip from 'components/Tooltip/Tooltip.vue'

export default {
    name: 'DynamicTable',
    components: {
        Tooltip,
    },
    props: {
        headers: {
            type: Object,
            required: true,
        },
        rows: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        return {}
    },
}
