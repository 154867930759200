import { createApp } from "vue"
import Tooltip from "./Tooltip"

const el = ".nyk-component--tooltip"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((TooltipDOM) => {
      const props = {
        tooltip: TooltipDOM.dataset.tooltip,
        tooltipName: TooltipDOM.dataset.tooltipName,
      }
      const component = createApp(Tooltip, props).use(Store)
      component.mount(TooltipDOM)
    })
  }
}
