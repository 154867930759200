import { createApp } from "vue"
import SignupStepReceipt from "./SignupStepReceipt"

const el = ".nyk-component--signup-step-receipt"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((SignupStepReceiptDOM) => {
      const component = createApp(SignupStepReceipt, {
        currentStepTexts: {
          title: SignupStepReceiptDOM.dataset.title,
          summary: SignupStepReceiptDOM.dataset.summary,
        },
      }).use(Store)
      component.mount(SignupStepReceiptDOM)
    })
  }
}
