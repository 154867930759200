import {createApp} from 'vue'
import options from './Breadcrumbs.options'

const el = '.nyk-component--bread-crumb'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    DOM.forEach((BreadCrumbDOM) => {
        const template = BreadCrumbDOM.outerHTML
        options.template = template
        const component = createApp(options).use(Store)
        component.mount(BreadCrumbDOM)
    })
}

