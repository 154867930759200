import {createApp} from 'vue'
import ContactStepNewsletters from './ContactStepNewsletters'

const el = '.nyk-component--contact-step-newsletters'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((ContactStepNewslettersDOM)=>{
            const component = createApp(ContactStepNewsletters).use(Store)
            component.mount(ContactStepNewslettersDOM)
        })
    }
}