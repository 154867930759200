<script>
import options from "./DynamicDocumentList.options"
export default options
</script>

<template>
  <article class="nyk-document-list nyk-dynamic-document-list">
    <div v-if="title && description" class="nyk-document-list__header">
      <h2 v-if="title" class="nyk-document-list__header__title">
        {{ title }}
      </h2>

      <p v-if="description" class="nyk-document-list__header__description">
        {{ description }}
      </p>
    </div>

    <template v-for="(list, index) in documentLists" :key="index">
      <div class="nyk-document-list__content">
        <div class="nyk-document-list__content__group">
          <div class="nyk-document-list__content__group__header">
            <strong class="nyk-document-list__content__group__header__title">
              {{ list.title }}
            </strong>
          </div>

          <ul
            v-if="list.items.length > 0"
            class="nyk-document-list__content__group__list">
            <template v-for="(item, index) in list.items" :key="index">
              <li class="nyk-document-list__content__group__list__item">
                <a
                  :href="item.href"
                  class="nyk-document-list__content__group__list__item__link">
                  <div
                    class="nyk-document-list__content__group__list__item__link__filetype">

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      :stroke-width="3"
                      :stroke-miterlimit="10">
                      <use :xlink:href="`${item.icon}`"></use>
                    </svg>

                    <span
                      class="nyk-document-list__content__group__list__item__link__filetype__screen-reader"
                      >{{ item.icon.replace("#","") }} ikon</span
                    >
                  </div>
                  <div
                    class="nyk-document-list__content__group__list__item__link__info">
                    <small
                      class="nyk-document-list__content__group__list__item__link__info__date"
                      >{{ item.date }}</small
                    >
                    <p
                      class="nyk-document-list__content__group__list__item__link__info__name">
                      {{ item.title }}
                    </p>
                  </div>
                  <div
                    class="nyk-document-list__content__group__list__item__link__download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 29 29">
                      <g
                        stroke="#07094A"
                        stroke-miterlimit="10"
                        stroke-width="2">
                        <path
                          stroke-linecap="square"
                          d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                        <path d="M14.5 0v20" />
                        <path
                          stroke-linecap="square"
                          d="M7.75 13.333 14.5 20l6.75-6.667" />
                      </g>
                    </svg>
                    <span
                      class="nyk-document-list__content__group__list__item__link__download__screen-reader"
                      >Download fil</span
                    >
                  </div>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </article>
</template>
