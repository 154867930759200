import { computed, onBeforeMount, ref } from "vue"
import getCenterInformation from "../FindMap/methods/getCenterInformation"

import FilterTabs from "../../../../Frontend/src/components/FilterTabs/FilterTabs.vue"
import CenterMap from "../FindMap/CenterMap.vue"
import FindCenterContactPersons from "../FindCenterContactPersons/FindCenterContactPersons.vue"

export default {
  name: "FindCenterMapAndEmployees",
  components: {
    FilterTabs,
    CenterMap,
    FindCenterContactPersons,
  },
  props: {
    mapkey: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    centersApiUrl: {
      type: String,
      required: true,
    },
    contactPersonsApiUrl: {
      type: String,
      required: true,
    },
    defaultEmployeesImage: {
      type: String,
      required: true,
    },
    contactUrl: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const centerInformation = ref([])

    onBeforeMount(async () => {
      centerInformation.value = await getCenterInformation(props.centersApiUrl)
    })

    const FindCenterViews = Object.freeze({
      Maps: "GoogleMaps",
      Employees: "Employees",
    })

    const showCenterMaps = computed(() => {
      return activeTab.value === FindCenterViews.Maps
    })

    const activeTab = ref(FindCenterViews.Maps)
    const tabs = [
      { id: FindCenterViews.Maps, title: "Find Center" },
      { id: FindCenterViews.Employees, title: "Find Medarbejder" },
    ]

    const findCenterFilterChanged = (value) => {
      activeTab.value = value
    }

    return {
      tabs,
      showCenterMaps,
      findCenterFilterChanged,
      centerInformation,
    }
  },
}
