import { onMounted, ref } from "vue"
import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"

export default {
  name: "SignupStepReceipt",
  props: _signupStepsCommonProps,
  setup() {
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })

    return {
      contentClassnames,
    }
  },
}
