<script>
import options from './CalculatorInfoBox.options'
export default options
</script>

<template>
    <article class="nyk-calculator-info-box">
        <h2 class="nyk-calculator-info-box__title">{{ data.title }}</h2>
        <div class="nyk-calculator-info-box__description" v-html="data.description"></div>

        <a class="nyk-calculator-info-box__action" v-if="data.link">
            <span class="nyk-calculator-info-box__action-text">{{ data.link.text }}</span>
            <div class="nyk-calculator-info-box__action-icon">
                <div class="nyk-button__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                        <path fill="#07094A" d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                    </svg>
                </div>
            </div>
        </a>
    </article>
</template>
