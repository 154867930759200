import {createApp} from 'vue'
import CalculatorTable from './CalculatorTable'

const el = '.nyk-component--calculator-table'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((CalculatorTableDOM)=>{
            const component = createApp(CalculatorTable).use(Store)
            component.mount(CalculatorTableDOM)
        })
    }
}