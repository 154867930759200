import { ref } from "vue"

export default {
    name: "BreadCrumb",
    setup() {
        const mobileAllBreadcrumbsShown = ref(false)
        const dotsRef = ref(null)

        const toggleAllBreadcrumbs = () => {
            if (dotsRef.value) {
                setTimeout(() => {
                    const nextSiblingLi = dotsRef.value.nextElementSibling
                    nextSiblingLi?.querySelector("a")?.focus()
                }, 50)
            }
            return (mobileAllBreadcrumbsShown.value = !mobileAllBreadcrumbsShown.value)
        }

        return {
            dotsRef,
            mobileAllBreadcrumbsShown,
            toggleAllBreadcrumbs,
        }
    },
}
