/**
     * 
     * @param {string} valueToValidate 
     * @returns 
     */
const notEmpty = function (valueToValidate) {
    const isString = typeof valueToValidate === 'string'
    const isValid = isString ? !!(valueToValidate.length > 1) : false
    return isValid
}

export default notEmpty