import { computed } from 'vue'
import applyThousandSeperator from '../../js/applyThousandSeperator'

export default {
    name: 'CalculatorSummary',
    props: {
        data: {
            type: Object,
            required: true,
        },
        labels: {
            type: Object,
            required: true,
        },
        hasGraph: {
            type: Boolean,
            required: false,
            default: true,
        },
        onCallback: {
            type: Function,
            required: true,
            default: () => {},
        },
        hasHousingTypes: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const mortgageLoanProcents = computed(() => {
            const total = props.data.bankLoanValue + props.data.mortgageLoanValue
            const procent = (props.data.mortgageLoanValue / total) * 100
            return procent.toFixed(2)
        })

        return {
            mortgageLoanProcents,
            applyThousandSeperator,
        }
    },
}
