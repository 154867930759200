import { createApp } from "vue"
import InvestmentFundTable from "./InvestmentFundTable"

const el = ".nyk-component--investment-fund-table"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((InvestmentFundTableDOM) => {
      const props = {
        apiUrl: InvestmentFundTableDOM.dataset.apiUrl,
        model: InvestmentFundTableDOM.dataset.model
          ? JSON.parse(InvestmentFundTableDOM.dataset.model)
          : {},
      }
      const component = createApp(InvestmentFundTable, props).use(Store)
      component.mount(InvestmentFundTableDOM)
    })
  }
}
