import { computed } from 'vue'

export default {
    name: "SiteSearchingResult",
    props: {
        tag: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        href: {
            type: String,
            required: true
        },
        filename: {
            type: String,
            required: false
        }
    },
    setup(props) {
        
        const getFileExtension = computed(() => {
            const fileNameSplit = props.filename.split('.')
            return fileNameSplit[1]??fileNameSplit[0]
        })


        return {
            getFileExtension
        }
    }
}