/**
 * 
 * @param {function} callback 
 */
export default function(callback){
    
    const callbackList = Array.isArray(window.__onScrollCallbacks) ? window.__onScrollCallbacks : []

    callbackList.push(callback)
    window.__onScrollCallbacks = callbackList

    window.onscroll = function(){
        callbackList.forEach(callback => callback());
    }

}