<script>
import options from "./FindCenterContactPersons.options"
export default options
</script>

<template>
  <article class="nyk-find-center-contact-persons">
    <div class="nyk-find-center-contact-persons__section">
      <div class="nyk-find-center-contact-persons__section__controls">
        <div class="nyk-find-center-contact-persons__section__controls__search">
          <div class="nyk-form nyk-js--form">
            <div class="nyk-form__content">
              <input
                ref="employSearch"
                v-on:keyup="searchInputUpdated"
                class="nyk-form__content__input"
                type="text"
                maxlength="10000"
                autocomplete="off"
                id="employSearch"
                name="employSearch"
                placeholder="Navn på medarbejder"
                aria-required="true" /><label class="nyk-form__content__label" for="employSearch">Navn på medarbejder</label>

              <button @click="resetEmploySearch" class="nyk-form__content__reset-icon nyk-form-js--reset" aria-label="Slet indtastning">
                <span class="nyk-icon nyk-icon--ui-close"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="nyk-find-center-contact-persons__section__controls__select nyk-form nyk-js--form nyk-form--select">
          <div class="nyk-form--select__content">
            <select
              class="nyk-form--select__content__select"
              name="centerSelect"
              id="centerSelect"
              ref="centerSelect"
              v-on:change="centerValueUpdated">
              <option value="">Vælg center</option>
              <template v-for="(option, index) in options">
                <option :value="option.value" :selected="option.selected">{{ option.name }}</option>
              </template>
            </select>
            <label class="nyk-form--select__content__label" for="centerSelect">Center</label>
            <div class="nyk-form--select__content__error-icon">
              <span class="nyk-icon nyk-icon--ui-form-error"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="nyk-find-center-contact-persons__section__employees">
        <section class="nyk-person-card-layout">
          <div class="nyk-person-card-layout__content nyk-person-card-layout__content--4-cols">
            <template v-for="(employ, index) in employees" :key="index">
              <template v-if="index < showEmployeesCount">
                <article class="nyk-person-card">
                  <div class="nyk-person-card__content">
                    <div class="nyk-person-card__content__image">
                      <picture>
                        <img
                          class="nyk-person-card__content__image__img"
                          :src="employ.pictureUrl"
                          :onerror="handleImageError()"
                          alt="medarbejder billede" />
                      </picture>
                    </div>

                    <div class="nyk-person-card__content__body">
                      <div class="nyk-person-card__content__body__header">
                        <h3 class="nyk-person-card__content__body__header__title">
                          {{ employ.name }}
                        </h3>
                        <h4 class="nyk-person-card__content__body__header__job-title">
                          {{ employ.title }}
                        </h4>
                      </div>
                    </div>

                    <div class="nyk-person-card__content__bottom">
                      <div class="nyk-person-card__content__bottom__actions">
                        <a :href="employ.url" class="nyk-person-card__content__bottom__actions__link">
                          <span class="nyk-person-card__content__bottom__actions__link__name">Send besked</span>
                          <div
                            class="nyk-person-card__content__bottom__actions__link__button nyk-button nyk-button--link-circle-arrow right">
                            <div class="nyk-button__icon">
                              <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                <path
                                  fill="#07094A"
                                  d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                              </svg>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </article>
              </template>
            </template>
          </div>
          <div v-if="employeesCount > showEmployeesCount" class="nyk-person-card-layout__actions">
            <button v-on:click="handleShowAllEmployees" class="nyk-button nyk-button--outline-primary">Vis flere</button>
          </div>
        </section>
      </div>
    </div>
  </article>
</template>
