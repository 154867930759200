import {createApp} from 'vue'
import SignupStepEmail from './SignupStepEmail'

const el = '.nyk-component--signup-step-email'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((SignupStepEmailDOM)=>{
            const component = createApp(SignupStepEmail,{
                currentStepTexts: {
                    title: SignupStepEmailDOM.dataset.title,
                    summary: SignupStepEmailDOM.dataset.summary,
                },
                stepCounterText: SignupStepEmailDOM.dataset.stepcountertext
            }).use(Store)
            component.mount(SignupStepEmailDOM)
        })
    }
}