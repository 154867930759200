// should be build in the all future projects
export default function (component, componentDOM) {
    const props = {}

    if (!component.props) return
    Object.keys(component.props).forEach(function (componentKey) {
        const type = component.props[componentKey].type.name
        // const required = component.props[componentKey].required

        const data = componentDOM.dataset
        Object.keys(data).forEach(function (DOMComponentKey) {
            if (componentKey === DOMComponentKey) {
                // handleLabels from componentDOM
                props[componentKey] = getFormattedData(type, data[DOMComponentKey])
            }
        })
    })

    return props
}

const getComponentLabelCollection = function (component, componentKey, data) {
    const formattedData = JSON.parse(data[componentKey])
    if (formattedData instanceof Array) {
        const currentCollection = formattedData.find((collection) => collection.collectionName === component.name)
        return currentCollection.labels
    }
    return formattedData.labels
}

const getFormattedData = function (type, data) {
    switch (type) {
        case 'Object':
            return data ? JSON.parse(data) : {}
        case 'Array':
            return data ? JSON.parse(data) : []
        case 'Boolean':
            return data === 'true' ? true : false
        case 'Number':
            return parseFloat(data)
        default:
            return data
    }
}
