import {createApp} from 'vue'
import SiteSearching from './SiteSearching'

const el = '.nyk-component--site-searching'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((SiteSearchingDOM)=>{

            const props = {
                searchUrl: SiteSearchingDOM.dataset.searchUrl,
                lang: SiteSearchingDOM.dataset.lang,
                labels: SiteSearchingDOM.dataset.labels ? JSON.parse(SiteSearchingDOM.dataset.labels) : null
            }
            const component = createApp(SiteSearching, props).use(Store)
            component.mount(SiteSearchingDOM)
        })
    }
}