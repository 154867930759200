import { ref, onMounted } from "vue"
import _sendCalculationStepsCommonProps from "../../foundation/js/configurations/_sendCalculationStepsCommonProps"
import SendCalculationStepActions from "../../../../Features/Blocks/SendCalculation/Actions/SendCalculationStepActions.vue"

export default {
    name: "SendCalculationStepInfo",
    props: _sendCalculationStepsCommonProps,
  components: {
      SendCalculationStepActions,
  },
  setup() {
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })


    return {
      contentClassnames,
    }
  }
}
