export default {
    namespaced: true,
    state: {
        modalTitle: '',
        componentName: '',
        componentProps: {},
        htmlContent: '',
        open: false,
        style: '',
    },
    mutations: {
        set_modal_title(state, value) {
            state.modalTitle = value
        },
        set_component_name(state, value) {
            state.componentName = value
        },
        set_component_props(state, value) {
            state.componentProps = value
        },
        set_html_content(state, value) {
            state.htmlContent = value
        },
        set_style(state, value) {
            state.style = value
        },
        set_open(state, value) {
            state.open = value
        },
    },
    actions: {
        setModalContent(context, payload) {
            if (payload.modalTitle) context.commit('set_modal_title', payload.modalTitle)
            if (payload.componentName) context.commit('set_component_name', payload.componentName)
            if (payload.componentProps) context.commit('set_component_props', payload.componentProps)
            if (payload.htmlContent) context.commit('set_html_content', payload.htmlContent)
            if (payload.style) context.commit('set_style', payload.style)
        },
        setOpen(context, payload) {
            context.commit('set_open', payload.open)
            if (!payload.open) {
                context.commit('set_component_name', '')
                context.commit('set_component_props', {})
                context.commit('set_modal_title', '')
            }
        },
    },
}
