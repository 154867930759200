export default {
  name: "DynamicDocumentList",
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    documentLists: {
      type: Object,
      required: false,
    },
  },
  setup(props) {},
}
