export const getJobPostingsHeader = (data) => {
    return [
        { name: data.positionLabel },
        {
            name: data.cityLabel,
            styles: 'text-right',
        },
    ]
}

export const getJobPostingsRows = (data) => {
    const array = []
    Array.from(data).map((item) => {
        array.push([
            { name: item.title, href: '', reference: item.reference },
            {
                name: item.city,
                styles: 'text-right',
            },
        ])
    })

    return array
}

export const getJobPostingsFilters = (data) => {
    const array = [[]]

    Array.from(data.jobTypes.data).map((filter) => {
        array[0].push({ name: filter.label, value: filter.code })
    })

    return array
}

export const getJobPostingsNumberOfResults = (data) => {
    return data.jobPostingsList.length ?? 0
}
