const createMarker = function (location, markerThemeExtractor, currentMapTheme, hoverEnterLocation, hoverLeaveLocation, clickLocation, highlightLocation, map) {
    var latLng = { lat: location.latitude, lng: location.longitude };
    var highlighted = highlightLocation == location;
    var useMediumIcon = location.useMediumIcon;
    var useBigIcon = location.useBigIcon;

    var marker = new window.google.maps.Marker({
        position: latLng,
        map: map.value,
        title: location.Name,
        icon: markerThemeExtractor(highlighted, useMediumIcon, useBigIcon),
        ...currentMapTheme.value.markerOptions
    });

    marker.addListener('mouseover', function () {
        hoverEnterLocation(location);
    });

    marker.addListener('mouseout', function () {
        hoverLeaveLocation();
    });

    marker.addListener('click', function () {
        clickLocation(location);
    });

    return marker;
}

const updatePins = function (props, mapLocations, pins, markerThemeExtractor, currentMapTheme, highlightLocation,map, updateMarkerHighlight) {

    const hoverEnterLocation = props.hoverEnterLocation ?? function (location) {
        highlightLocation.value = location
        if (pins.value !== null) {
            updateMarkerHighlight();
        }
    }
    const hoverLeaveLocation = props.hoverLeaveLocation ?? function () {
        highlightLocation.value = undefined
        if (pins.value !== null) {
            updateMarkerHighlight();
        }
    }
    const clickLocation = props.clickLocation ?? function (location) {
        window.location.href = location.centerPageUrl
    }

    mapLocations.value.forEach((location) => {
        pins.value.push({
            marker: createMarker(location, markerThemeExtractor, currentMapTheme, hoverEnterLocation, hoverLeaveLocation, clickLocation, highlightLocation, map),
            location
        })
    })

    return pins.value
}

export default updatePins