var markerUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent('<svg width="23px" height="27px" viewBox="0 0 23 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
    + '<title>Group 8</title>'
    + '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">'
    + '<g id="Find_kort_active" transform="translate(-844.000000, -976.000000)">'
    + '<g id="Group-8" transform="translate(844.000000, 976.000000)">'
    + '<path d="M22.5,0.5 L22.5,22.3461431 L15.6360039,22.3459953 L12.0226575,26.2626896 L8.40794026,22.3459959 L0.5,22.3461445 L0.5,0.5 L22.5,0.5 Z" id="Rectangle-7-Copy-2" stroke="#FFFFFF" fill="#0F1E82"></path>'
    + '<path d="M5.22727273,15.9652474 L5.24349469,6.3486392 C5.24349469,6.23791698 5.3573916,6.23076923 5.3573916,6.23076923 L10.405429,6.23076923 C10.5163421,6.22796992 10.5300677,6.33844754 10.5330515,6.33559387 L13.9543921,12.3483487 C13.9894307,12.4015084 14.069653,12.4287405 14.1331659,12.4043621 C14.1997478,12.3799836 14.2262613,12.2821436 14.2262613,12.2821436 L14.0828671,6.3557326 C14.0811621,6.28243416 14.0965075,6.24362431 14.1767298,6.23076923 L17.6279938,6.23076923 C17.763971,6.23076923 17.7759916,6.33265868 17.7721553,6.33004961 L17.7721553,15.932308 C17.7721553,15.932308 17.7562983,16.0600707 17.617252,16.0586846 L12.750205,16.0616455 C12.6385247,16.0616455 12.5470491,16.0600707 12.4430414,15.8990424 L9.05691001,9.8684318 C8.93073678,9.71449693 8.75417952,9.82790975 8.75417952,10.0006788 L8.94991852,15.9294543 C8.96364413,16.014249 8.88725817,16.064555 8.81308877,16.0586846 L5.34673508,16.0616455 C5.28296645,16.0629243 5.2243982,16.014249 5.22727273,15.9652474 L5.22727273,15.9652474 Z" id="Path-Copy-2" fill="#FFFFFF"></path>'
    + '</g>'
    + '</g>'
    + '</g>'
    + '</svg>')

export default {
    findNykreditTheme() {
        return {
            markerDefault: {
                url: markerUrl,
                anchor: new window.google.maps.Point(11, 27),
                scaledSize: new window.google.maps.Size(23, 27)
            },
            markerHighlight: {
                url: markerUrl,
                anchor: new window.google.maps.Point(17, 39),
                scaledSize: new window.google.maps.Size(34, 39)
            },
            markerMedium: {
                url: markerUrl,
                anchor: new window.google.maps.Point(13, 30),
                scaledSize: new window.google.maps.Size(26, 30)
            },
            markerMediumHighlight: {
                url: markerUrl,
                anchor: new window.google.maps.Point(19, 44),
                scaledSize: new window.google.maps.Size(38, 44)
            },
            markerBig: {
                url: markerUrl,
                anchor: new window.google.maps.Point(15, 35),
                scaledSize: new window.google.maps.Size(30, 35)
            },
            markerBigHighlight: {
                url: markerUrl,
                anchor: new window.google.maps.Point(22, 51),
                scaledSize: new window.google.maps.Size(44, 51)
            },
            markerOptions: {
                animation: window.google.maps.Animation.DROP
            },
            mapOptions: {
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dadada"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#68D2DF"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    }
                ]
            }
        }
    }
}