<script>
import options from "./SignupMultipleNewsletters.options"
export default options
</script>

<template>
  <div class="nyk-signup-multiple-newsletters">
    <component
      :is="currentStepName"
      :nextStepCallback="validateInputAndProceed"
      :previousStepCallback="returnToPreviousStep"
      :displayInputError="displayInputError"
      :currentStepTexts="currentStepTexts"
      :getNamedFieldInputData="getNamedFieldInputData"
      :newslettersData="newsletters"
      :hasPreviousStep="hasPreviousStep"
      :showFieldErrorMessage="showFieldErrorMessage"
      :resetSignupForm="resetSignupForm"
      :stepCounterText="stepCounterText" />
  </div>
</template>
