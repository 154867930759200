import { computed, ref } from 'vue'

export default {
    name: 'SwitchLoanTypeContent',
    props: {
        currentHousingType: {
            type: Array,
            required: true,
            default: [],
        },
        labels: {
            type: Object,
            required: true,
        },
        selectedLoanType: {
            type: String,
            required: true,
            default: '',
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        onCallback: {
            type: Function,
            required: true,
            default: () => {},
        },
    },
    setup(props) {
        const changeLoanType = (loanType) => {
            if (props.onCallback) {
                props.onCallback(loanType, props.index)
            }
        }

        return {
            changeLoanType,
        }
    },
}
