import { computed, onMounted, ref } from 'vue'
import NewLoanCalculatorInputs from '../components/NewLoanCalculatorInputs/NewLoanCalculatorInputs.vue'
import mapNewLoanUrlParams from '../js/mapNewLoanUrlParams'

export default {
    name: 'NewLoadCalculatorInput',
    components: {
        NewLoanCalculatorInputs,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        housingTypes: {
            type: Array,
            required: true,
        },
        initData: {
            type: Object,
            required: false,
        },
    },
    setup(props) {
        const housePrice = ref('')
        const ownPayment = ref('')
        const housingType = ref('')
        const formValid = ref(false)
        const displayErrors = ref(false)

        const onChangeCallback = function (data) {
            housePrice.value = data.housePrice
            ownPayment.value = data.ownPayment
            housingType.value = data.housingType
            formValid.value = data.formValid
        }

        const getCurrentHouseTypeObject = () => props.housingTypes.find((x) => x.keyName === housingType.value)

        const submit = function () {
            displayErrors.value = true

            if (formValid.value) {
                displayErrors.value = false
                window.location.href = mapNewLoanUrlParams({
                    housePrice: housePrice.value,
                    ownPayment: ownPayment.value,
                    housingType: housingType.value,
                    mortgageLoanInterestOnlyLength: props.initData.mortgageLoanInterestOnlyLength,
                    mortgageLoanTenureLength: props.initData.mortgageLoanTenureLength,
                    bankLoanTenureLength: props.initData.bankLoanTenureLength,
                    productType: getCurrentHouseTypeObject()?.productType ?? '',
                })
            }
        }

        return {
            displayErrors,
            onChangeCallback,
            submit,
        }
    },
}
