import { createApp } from "vue"
import SendCalculationStepActions from "./SendCalculationStepActions"

const el = ".nyk-component--signup-step-actions"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
      DOM.forEach((SendCalculationStepActionsDOM) => {
      const props = {
        previousStepCallback: SendCalculationStepActionsDOM.dataset.previousStepCallback,
        nextStepCallback: SendCalculationStepActionsDOM.dataset.nextStepCallback,
        nextStepButtonText: SendCalculationStepActionsDOM.dataset.nextStepButtonText,
        stepCounterText: SendCalculationStepActionsDOM.dataset.stepCounterText,
        hasPreviousStep: SendCalculationStepActionsDOM.dataset.hasPreviousStep,
      }
      const component = createApp(SendCalculationStepActions, props).use(Store)
      component.mount(SendCalculationStepActionsDOM)
    })
  }
}
