import { ref } from 'vue'

export default {
    name: "FooterContact",
    props: {
        searchUrl: {
            type: String,
            required: true
        }
    },
    setup(props) {

        const searchInput = ref("")
        
        const navigateToSearch = () => {
            window.location.href = props.searchUrl + `?q=${searchInput.value}`
        }

        return {
            navigateToSearch,
            searchInput
        }
    }
}