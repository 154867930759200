w
<script>
import options from './NewLoadCalculatorInput.options'
export default options
</script>

<template>
    <article class="nyk-new-load-calculator-input">
        <div class="nyk-new-load-calculator-input__content-wrapper">
            <div class="nyk-new-load-calculator-input__content">
                <h2 class="nyk-new-load-calculator-input__content-title">{{ labels.title }}</h2>

                <NewLoanCalculatorInputs
                    :labels="labels"
                    :housingTypes="housingTypes"
                    :initData="initData"
                    :displayErrors="displayErrors"
                    :onChangeCallback="onChangeCallback" />

                <button class="nyk-button nyk-button--filled-primary" v-on:click="submit">{{ labels.buttonLabel }}</button>
            </div>
        </div>
    </article>
</template>
