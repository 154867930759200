import { ref, watch } from "vue"

export default {
  name: "FeedbackStepTwo",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const feedbackText = ref("")

    const submitFeedback = () => {
      if (props.callback) {
        props.callback(feedbackText.value)
      }
    }

    const feedbackTextUpdated = (e) => {
      feedbackText.value = e.target.innerText
    }

    return {
      feedbackText,
      submitFeedback,
      feedbackTextUpdated,
    }
  },
}
