import { ref, watch, onMounted } from "vue";
import GtmVideoService from "./GtmVideoService";

export default {
  name: "VideoBlock",
  props: {
    videoBlock: {
      type: Element,
      required: true,
    },
  },
  setup(props) {
    const cookieAccepted = ref(false);
    const videoElement = ref(null);
    const videoWrapper = ref(null);
    const lazyLoad = ref(false);
    const timeout = ref(null);

    const initCookieAccepted = () => {
      videoWrapper.value = props.videoBlock.querySelector(".nyk-js--video");
      videoElement.value = props.videoBlock.querySelector(
        ".nyk-js--video-element"
      );

      if (!window.youTubeIframeAPIInserted) {
        insertYoutubeApi();
      }

      playOrQueue();
    };

    onMounted(() => {
      if (process.env.NODE_ENV === "development") {
        cookieAccepted.value = true;
      } else {
        cookieAccepted.value = CookieInformation.getConsentGivenFor(
          "cookie_cat_functional"
        );
      }
      window.youTubeCallbackQueue = window.youTubeCallbackQueue || [];

      if (cookieAccepted.value) {
        initCookieAccepted();
      }
    });

    // TOOD: need a callback for then cookies is accepted
    // Callback should look for cookie and update
    // cookieAccepted.value = CookieInformation.getConsentGivenFor('cookie_cat_functional')
    const acceptCookieConsent = () => {
      CookieConsent.renew();
      window.addEventListener(
        "CookieInformationConsentGiven",
        function (event) {
          cookieAccepted.value = CookieInformation.getConsentGivenFor(
            "cookie_cat_functional"
          );
        },
        false
      );
    };

    watch(cookieAccepted, () => {
      if (cookieAccepted.value) {
        setTimeout(() => {
          initCookieAccepted();
        }, 150);
      }
    });

    const onTogglePlaceholder = (show, currentElement) => {
      const toggleElement = currentElement ?? videoWrapper.value;
      toggleElement.classList.toggle("nyk-video--video-playing", show);
    };

    const playOrQueue = () => {
      if (window.youTubeIframeAPIReady) {
        initVideo(props.videoBlock);
      } else {
        window.youTubeCallbackQueue.push(props.videoBlock);
        // triggered when YouTube iFrame API is ready (only listens to global var)
        window.onYouTubeIframeAPIReady = () => onYouTubeIframeAPIReady();
      }
    };

    const insertYoutubeApi = () => {
      const newScriptEl = document.createElement("script");
      newScriptEl.src = "https://www.youtube.com/iframe_api";

      const firstScriptEl = document.getElementsByTagName("script")[0];
      firstScriptEl.parentNode.insertBefore(newScriptEl, firstScriptEl);

      window.youTubeIframeAPIInserted = true;
    };

    const onYouTubeIframeAPIReady = () => {
      window.youTubeIframeAPIReady = true;
      window.youTubeCallbackQueue.forEach((elm) => initVideo(elm));
    };

    const onPlayerReady = (player) => {
      if (lazyLoad.value) {
        onPlayVideo(player);
      }
    };

    const onPlayVideo = (player) => {
      player.playVideo();
      //videoPlaceholder.classList.add('hide')
    };

    const onPlayerStateChange = (state) => {
      const currentElement = state.target.g;
      const currentVideoContainer = currentElement?.closest(".nyk-js--video");

      if (state.data == window.YT.PlayerState.PAUSED) {
        timeout.value = setTimeout(() => {
          onTogglePlaceholder(false, currentVideoContainer);
        }, 300);
      }

      if (state.data == window.YT.PlayerState.PLAYING) {
        onTogglePlaceholder(true, currentVideoContainer);
      }

      if (state.data == window.YT.PlayerState.BUFFERING) {
        clearTimeout(timeout.value);
      }

      // when video has ended show placeholder and rewind
      // this make it act like before youtube changes of sep. 2018
      if (state.data == window.YT.PlayerState.ENDED) {
        setTimeout(() => {
          onTogglePlaceholder(false, currentVideoContainer);
        }, 150);

        // Doesn't seen to work
        //this.player.cueVideoById({videoId: videoElementID})
      }
    };

    const initVideo = (elm) => {
      const videoElementID = elm.querySelector(".nyk-js--video-element").dataset
        .ytPlayerId;
      const youtubeId = elm.querySelector(".nyk-js--video-element").dataset
        .youtubeId;

      let player = new YT.Player(videoElementID, {
        height: "390",
        width: "640",
        host: "https://www.youtube-nocookie.com",
        videoId: youtubeId,
        playerVars: {
          showinfo: 0,
          rel: 0,
          hl: "da-dk",
        },
        events: {
          onReady: () => onPlayerReady(player),
          onStateChange: (state) => onPlayerStateChange(state),
        },
      });

      const playButton = elm.querySelector(".nyk-js--play-button");
      playButton.addEventListener("click", () => {
        if (player.playVideo) {
          player.playVideo();
        }
      });

      // tracking
      let gtmVideoService = new GtmVideoService(player, videoElementID);
    };

    const playLocalVideo = (targetId) => {
      const video = document.getElementById(targetId)
      video?.play()
      onTogglePlaceholder()
    } 
 
    return {
      cookieAccepted,
      acceptCookieConsent,
      playLocalVideo
    };
  },
};
