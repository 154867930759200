<script>
import options from "./SearchOverlay.options"
export default options
</script>

<template>
    <article class="nyk-search-overlay">
        <div class="nyk-search-overlay__search">
            <button
                v-on:click.prevent="emptyInputNoFocus"
                tabindex="-1"
                class="nyk-js--search-menu-mobile nyk-search-overlay__search__icon nyk-search-overlay__search__icon--circle">
                <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13">
                    <path fill="#07094A" d="M16 5.782v1.456H3.005l4.456 5.014-.767.748L0 6.49 6.694 0l.767.748-4.456 5.034H16Z" />
                </svg>
            </button>

            <button class="nyk-search-overlay__search__icon" tabindex="-1">
                <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path
                        fill="#07094A"
                        fill-rule="evenodd"
                        d="M1.2 7.344a6.142 6.142 0 1 1 10.562 4.264l-.155.156A6.142 6.142 0 0 1 1.2 7.344Zm10.892 5.599a7.342 7.342 0 1 1 .849-.849l4.632 4.632.424.425-.848.848-.424-.424-4.633-4.632Z"
                        clip-rule="evenodd" />
                </svg>
            </button>

            <input
                v-model="searchInput"
                v-on:keydown.prevent.enter="navigateToSearch"
                v-on:focus="setInputInFocus"
                v-on:blur="setInputOutOfFocus"
                v-on:keydown.prevent.tab="navigateForwardInMenu"
                v-on:keydown.prevent.down="navigateForwardInMenu"
                v-on:keydown.prevent.right="navigateForwardInMenu"
                v-on:keydown.prevent.tab.shift="navigateBackInMenu"
                v-on:keydown.prevent.up="navigateBackInMenu"
                v-on:keydown.prevent.left="navigateBackInMenu"
                v-on:keydown.prevent.esc="closeSubmenu"
                :aria-label="searchPlaceholder"
                class="nyk-js--search-item nyk-js--search-overlay-input nyk-search-overlay__search__input"
                id="searchOverlayInput"
                name="searchOverlayInput"
                enterkeyhint="search"
                type="text" />

            <button
                v-on:click="emptyInput"
                class="nyk-search-overlay__search__close nyk-js--search-item"
                :class="{ 'nyk-search-overlay__search__close--show': inputIsNotEmpty }"
                aria-label="reset input value">
                <span class="nyk-icon nyk-icon--ui-close"></span>
            </button>
        </div>

        <template v-if="inputIsNotEmpty">
            <div class="nyk-search-overlay__content nyk-search-overlay__content--results">
                <div class="nyk-search-overlay__content__results nyk-search-overlay__content__results--suggestions">
                    <ul class="nyk-search-overlay__content__results__list">
                        <template v-for="(result, index) in searchResults">
                            <li class="nyk-search-overlay__content__results__list__item">
                                <a
                                    v-on:keydown.prevent.tab="navigateForwardInMenu"
                                    v-on:keydown.prevent.down="navigateForwardInMenu"
                                    v-on:keydown.prevent.right="navigateForwardInMenu"
                                    v-on:keydown.prevent.tab.shift="navigateBackInMenu"
                                    v-on:keydown.prevent.up="navigateBackInMenu"
                                    v-on:keydown.prevent.left="navigateBackInMenu"
                                    v-on:keydown.prevent.esc="closeSubmenu"
                                    :href="result.href"
                                    class="nyk-js--search-item nyk-search-overlay__content__results__list__item__link nyk-button nyk-button--link-menu">
                                    {{ result.title }}
                                    <div class="nyk-button__icon">
                                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                            <path
                                                fill="#07094A"
                                                d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
                                        </svg>
                                    </div>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="nyk-search-overlay__content">
                <div class="nyk-search-overlay__content__results">
                    <h3 class="nyk-search-overlay__content__results__title">
                        {{ searchResultTitle }}
                    </h3>

                    <ul class="nyk-search-overlay__content__results__list">
                        <template v-for="(suggest, index) in searchSuggestionsJson">
                            <li class="nyk-search-overlay__content__results__list__item">
                                <a
                                    v-on:keydown.prevent.tab="navigateForwardInMenu"
                                    v-on:keydown.prevent.down="navigateForwardInMenu"
                                    v-on:keydown.prevent.right="navigateForwardInMenu"
                                    v-on:keydown.prevent.tab.shift="navigateBackInMenu"
                                    v-on:keydown.prevent.up="navigateBackInMenu"
                                    v-on:keydown.prevent.left="navigateBackInMenu"
                                    v-on:keydown.prevent.esc="closeSubmenu"
                                    :href="suggest.url"
                                    :target="suggest.target"
                                    class="nyk-js--search-item nyk-search-overlay__content__results__list__item__link nyk-button nyk-button--link-menu">
                                    {{ suggest.name }}
                                    <div class="nyk-button__icon">
                                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                            <path
                                                fill="#07094A"
                                                d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
                                        </svg>
                                    </div>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>

                <div class="nyk-search-overlay__content__cards">
                    <article class="nyk-search-overlay__content__cards__card">
                        <h3 class="nyk-search-overlay__content__cards__card__title">
                            {{ cardTopJson.title }}
                        </h3>
                        <div class="nyk-search-overlay__content__cards__card__description" v-html="cardTopJson.description"></div>

                        <div class="nyk-search-overlay__content__cards__card__action">
                            <a
                                v-on:keydown.prevent.tab="navigateForwardInMenu"
                                v-on:keydown.prevent.down="navigateForwardInMenu"
                                v-on:keydown.prevent.right="navigateForwardInMenu"
                                v-on:keydown.prevent.tab.shift="navigateBackInMenu"
                                v-on:keydown.prevent.up="navigateBackInMenu"
                                v-on:keydown.prevent.left="navigateBackInMenu"
                                v-on:keydown.prevent.esc="closeSubmenu"
                                :href="cardTopJson.actionUrl"
                                class="nyk-js--search-item nyk-search-overlay__content__cards__card__action__link">
                                <div class="nyk-search-overlay__content__cards__card__action__link__text">
                                    {{ cardTopJson.actionText }}
                                </div>

                                <div
                                    class="nyk-search-overlay__content__cards__card__action__link__button nyk-button nyk-button--link-circle-arrow right">
                                    <div class="nyk-button__icon">
                                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                            <path
                                                fill="#07094A"
                                                d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
                                        </svg>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </article>

                    <article
                        class="nyk-search-overlay__content__cards__card nyk-search-overlay__content__cards__card--yellow nyk-search-overlay__content__cards__card--small">
                        <a
                            v-on:keydown.prevent.tab="navigateForwardInMenu"
                            v-on:keydown.prevent.down="navigateForwardInMenu"
                            v-on:keydown.prevent.right="navigateForwardInMenu"
                            v-on:keydown.prevent.tab.shift="navigateBackInMenu"
                            v-on:keydown.prevent.up="navigateBackInMenu"
                            v-on:keydown.prevent.left="navigateBackInMenu"
                            v-on:keydown.prevent.esc="closeSubmenu"
                            :href="cardBottomJson.actionUrl"
                            class="nyk-js--search-item nyk-search-overlay__content__cards__card__title-link">
                            <h3 class="nyk-search-overlay__content__cards__card__title-link__title">
                                {{ cardBottomJson.title }}
                            </h3>
                            <div
                                class="nyk-search-overlay__content__cards__card__title-link__button nyk-button nyk-button--link-circle-arrow right">
                                <div class="nyk-button__icon">
                                    <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                        <path
                                            fill="#07094A"
                                            d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </article>
                </div>
            </div>
        </template>
    </article>
</template>
