export default {
  name: "FeedbackStepThree",
  props: {
    message: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const handleClose = () => {
      if (props.callback) {
        props.callback()
      }
    }

    return {
      handleClose,
    }
  },
}
