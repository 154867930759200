<script>
import options from "./SendCalculation.options"
export default options
</script>

<template>
  <article class="nyk-contact-card" :class="sendCalculationClassNames">
    <component
      :is="currentStepName"
      :nextStepCallback="validateInputAndProceed"
      :previousStepCallback="returnToPreviousStep"
      :displayInputError="displayInputError"
      :currentStepTexts="currentStepTexts"
      :hasPreviousStep="hasPreviousStep"
      :resetSignupForm="resetSignupForm"
      :getNamedFieldInputData="getNamedFieldInputData"
      :showFieldErrorMessage="showFieldErrorMessage"
      :stepCounterText="stepCounterText"
      :labels="labels"/>
  </article>
</template>
