// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

export default function(callback, wait, immediate) {
    
    var timeout
    
    return function() {
    
      const context = this
    
      clearTimeout(timeout)
      timeout = window.setTimeout(()=>{
              timeout = undefined
              if (!immediate) callback.apply(context)
      }, wait)
      if (immediate && !timeout) callback.apply(context)
      
    }
  }