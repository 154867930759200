import { ref, computed } from 'vue'
import NewLoanCalculatorInputs from '../../../NewLoanCalculatorInputs/NewLoanCalculatorInputs.vue'

export default {
    name: 'AdjustLoanContent',
    components: {
        NewLoanCalculatorInputs,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        housingTypes: {
            type: Array,
            required: true,
        },
        initData: {
            type: Object,
            required: false,
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        onCallback: {
            type: Function,
            required: true,
            default: () => {},
        },
    },
    setup(props) {
        const housePrice = ref('')
        const ownPayment = ref('')
        const housingType = ref('')
        const bankLoanTenureLength = ref(props.initData.bankLoanTenureLength ?? '')
        const mortgageLoanTenureLength = ref(props.initData.mortgageLoanTenureLength ?? '')
        const mortgageLoanInterestOnlyLength = ref(props.initData.mortgageLoanInterestOnlyLength ?? '')
        const formValid = ref(false)
        const displayErrors = ref(false)

        const loanType = computed(() => {
            const currentType = props.housingTypes.find((item) => item.keyName === props.initData.housingType)
            return currentType?.loanTypes.find((item) => item.type === props.initData.loanType)
        })

        const onChangeCallback = function (data) {
            housePrice.value = data.housePrice
            ownPayment.value = data.ownPayment
            housingType.value = data.housingType
            formValid.value = data.formValid
        }

        const submit = function () {
            displayErrors.value = true

            if (formValid.value) {
                displayErrors.value = false
                if (props.onCallback) {
                    props.onCallback(
                        {
                            housePrice: housePrice.value,
                            ownPayment: ownPayment.value,
                            housingType: housingType.value,
                            mortgageLoanInterestOnlyLength: mortgageLoanInterestOnlyLength.value,
                            mortgageLoanTenureLength: mortgageLoanTenureLength.value,
                            bankLoanTenureLength: bankLoanTenureLength.value,
                        },
                        props.index
                    )
                }
            }
        }

        return {
            bankLoanTenureLength,
            mortgageLoanTenureLength,
            mortgageLoanInterestOnlyLength,
            loanType,
            displayErrors,
            onChangeCallback,
            submit,
        }
    },
}
