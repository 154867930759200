import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import modalTrapFocus from './modalTrapFocus'
import SwitchLoanTypeContent from './ModalContent/SwitchLoanTypeContent/SwitchLoanTypeContent.vue'
import AdjustLoanContent from './ModalContent/AdjustLoanContent/AdjustLoanContent.vue'

export default {
    name: 'Modal',
    components: {
        SwitchLoanTypeContent,
        AdjustLoanContent,
    },
    setup() {
        const store = useStore()
        const modalElementRef = ref(null)

        const modalTitle = computed(() => store.state.modal.modalTitle)
        const componentName = computed(() => store.state.modal.componentName)
        const componentProps = computed(() => store.state.modal.componentProps)
        const modalOpen = computed(() => store.state.modal.open)
        const htmlContent = computed(() => store.state.modal.htmlContent)
        const modalStyle = computed(() => store.state.modal.style)

        // Watch for changes in the modal open state when the store open value changes
        watch(modalOpen, (value) => {
            if (value) {
                modalElementRef.value.showModal()
                document.querySelector('body').classList.add('no-scroll')
            } else {
                modalElementRef.value.close()
                document.querySelector('body').classList.remove('no-scroll')
            }
        })

        // Function to close the modal in store
        const closeModal = () => store.dispatch('modal/setOpen', { open: false })

        onMounted(() => {
            document.addEventListener('keydown', (e) => modalTrapFocus(e, modalElementRef.value)) // Start trapping focus

            // Event listener when the modal is closed by click or keypress
            modalElementRef.value.addEventListener('close', (event) => {
                if (event.target.returnValue === '') {
                    closeModal()
                }
            })

            // Event listener to close the modal when the user clicks outside the modal
            modalElementRef.value.addEventListener('click', (event) => {
                if (event.target === modalElementRef.value) {
                    modalElementRef.value.close()
                }
            })
        })

        return {
            modalTitle,
            modalStyle,
            componentName,
            componentProps,
            htmlContent,
            modalElementRef,
            closeModal,
        }
    },
}
