import { onBeforeMount, ref } from "vue"
import MultiSelectDropdown from "../../../../Frontend/src/components/MultiSelectDropdown/MultiSelectDropdown.vue"

import debounce from "../../../../Frontend/src/foundation/js/debounce"
import getUrlParamPrefix from "../../../../Frontend/src/foundation/js/getUrlParamPrefix"

export default {
    name: "FindCenterContactPersons",
    components: {
        MultiSelectDropdown,
    },
    props: {
        apiUrl: {
            type: String,
            required: true,
        },
        centerInformation: {
            type: Array,
            required: true,
        },
        defaultImage: {
            type: String,
            required: true,
        },
        contactUrl: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const options = ref([])
        const employees = ref([])
        const employSearch = ref(null)
        const centerSelect = ref(null)
        const showEmployeesCount = ref(8)
        const employeesCount = ref(8)

        onBeforeMount(async () => {
            props.centerInformation.forEach((center) => {
                options.value.push({ value: center.centerId, name: center.name, selected: false })
            })
            fetchEmployees()
        })

        const setEmployees = (data) => {
            if (data.contactPersonsInformation) {
                employeesCount.value = data.contactPersonsInformation.length
                const employeesList = structuredClone(data.contactPersonsInformation)
                employeesList.forEach((employee) => {
                    employee.url = `${props.contactUrl}?advisorId=${employee.advisorId}`
                })
                employees.value = employeesList
            }
        }

        const fetchEmployees = () => {
            showEmployeesCount.value = 8

            let url = props.apiUrl

            if (employSearch.value && employSearch.value.value !== "") {
                const prefix = getUrlParamPrefix(url)
                url += `${prefix}query=${employSearch.value.value}`
            }

            if (centerSelect.value && centerSelect.value.value !== "") {
                const prefix = getUrlParamPrefix(url)
                url += `${prefix}centerId=${centerSelect.value.value}`
            }

            const request = new Request(url)

            fetch(request)
                .then((res) => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw new Error("Failed fetching employee data")
                    }
                })
                .then((json) => {
                    setEmployees(json)
                })
                .catch((err) => {
                    console.error(err)
                })
        }

        const resetEmploySearch = () => {
            employSearch.value.value = ""
            employSearch.value.focus()
            fetchEmployees()
        }

        const searchInputUpdated = debounce(() => {
            fetchEmployees()
        }, 500)

        const centerValueUpdated = () => {
            fetchEmployees()
        }

        const handleShowAllEmployees = () => {
            showEmployeesCount.value = 10000
        }

        const handleImageError = () => {
            return `this.onerror=null;this.src='${props.defaultImage}';this.alt='Fallback image';`
        }

        return {
            options,
            employees,
            employSearch,
            centerSelect,
            showEmployeesCount,
            employeesCount,
            resetEmploySearch,
            searchInputUpdated,
            centerValueUpdated,
            handleShowAllEmployees,
            handleImageError,
        }
    },
}