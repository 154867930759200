<script>
  import options from './JobPostingsTable.options'
  export default options
</script>

<template>
  <article class="nyk-job-postings-table">

    <div class="nyk-job-postings-table__filter">
      <template v-if="filters.length">
        <template v-for="filter, indexFilter in filters" :key="indexFilter">

          <div class="nyk-form nyk-js--form nyk-form--select">
            <div class="nyk-form--select__content">
                <select v-on:change="filterChanged" class="nyk-form--select__content__select" name="category" id="category">
                  <option value="">Alle</option>

                  <template v-for="option, indexOption in filter" :key="indexOption">
                    <option :value="option.value">{{ option.name }}</option>
                  </template>

                </select>
                <label class="nyk-form--select__content__label">Kategori</label>
                <div class="nyk-form--select__content__error-icon">
                    <span class="nyk-icon nyk-icon--ui-form-error"></span>
                </div>
            </div>
          </div>

        </template>
      </template>
    </div>

    <dynamicTable 
      :headers="headers"
      :rows="rows"
    />

  </article>
</template>
