<script>
import options from "./SendCalculationStepContent.options"
export default options
</script>

<template>
    <article class="nyk-signup-step nyk-contact-step-content">
        <div aria-live="polite" class="nyk-signup-step__content" :class="contentClassnames">
            <div class="nyk-signup-step__content__section--66-width nyk-signup-step__content__section--full-width-mobile">
                <div class="nyk-signup-step__header">
                    <h2 class="nyk-signup-step__title">{{ currentStepTexts.title }}</h2>
                </div>
            </div>

            <div class="nyk-signup-step__content__section--33-width nyk-signup-step__summary" v-html="currentStepTexts.summary"></div>

            <div class="nyk-contact-step-onboarding-content__inputs nyk-signup-step__content__section--66-width nyk-signup-step__content__section--full-width-mobile">
                <div class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--firstname">
                    <input class="nyk-signup-step__input"
                           :placeholder="labels.firstName ?? 'Fornavn'"
                           v-model="firstNameInput"
                           v-on:keyup.enter="nextStepCallback([{ field: 'firstname', value: firstNameInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('firstname')">
                            Indtast venligst et fornavn
                        </template>
                    </p>
                </div>
                <div class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--lastname">
                    <input class="nyk-signup-step__input"
                           :placeholder="labels.lastName ?? 'Efternavn'"
                           v-model="lastNameInput"
                           v-on:keyup.enter="nextStepCallback([{ field: 'lastname', value: lastNameInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('lastname')">
                            Indtast venligst et efternavn
                        </template>
                    </p>
                </div>
                <div class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--email">
                    <input class="nyk-signup-step__input"
                           :placeholder="labels.email ?? 'E-mail'"
                           v-model="emailInput"
                           v-on:keyup.enter="nextStepCallback([{ field: 'email', value: emailInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('email')">
                            Indtast venligst en email adresse
                        </template>
                    </p>
                </div>
            </div>

            <div class="nyk-signup-step__summary nyk-signup-step__summary--mobile" v-html="currentStepTexts.summary"></div>

            <div class="nyk-signup-step__mt-auto nyk-signup-step__content__section--full-width">
                <SendCalculationStepActions :previousStepCallback="previousStepCallback"
                                   :nextStepCallback="
                                   ()=>
                    nextStepCallback([
                    { field: 'firstname', value: firstNameInput },
                    { field: 'lastname', value: lastNameInput },
                    { field: 'email', value: emailInput }
                    ])
                    "
                    :nextStepButtonText="nextStepButtonText"
                    :stepCounterText="stepCounterText"
                    :hasPreviousStep="hasPreviousStep" />
            </div>
        </div>
    </article>
</template>