import {createApp} from 'vue'
import GoogleMapsWithMarkers from './GoogleMapsWithMarkers'

const el = '.nyk-component--google-maps-with-markers'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((GoogleMapsWithMarkersDOM)=>{
            const options = {
                mapkey: GoogleMapsWithMarkersDOM.dataset.mapkey,
                locations: JSON.parse(GoogleMapsWithMarkersDOM.dataset.locations),
                theme: GoogleMapsWithMarkersDOM.dataset.theme
            }
            const component = createApp(GoogleMapsWithMarkers,options).use(Store)
            component.mount(GoogleMapsWithMarkersDOM)
        })
    }
}