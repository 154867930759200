import { onMounted, ref } from "vue"
import _signupStepsCommonProps from "../../foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../../../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepActions/SignupStepActions.vue"

export default {
  name: "ContactStepComments",
  components: {
    SignupStepActions,
  },
  props: _signupStepsCommonProps,
  setup(props) {
    const commentsInput = ref(props.getNamedFieldInputData.comment)
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })
    return {
      commentsInput,
      contentClassnames,
    }
  },
}
