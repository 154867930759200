import {createApp} from 'vue'
import SignupStepFullname from './SignupStepFullname'

const el = '.nyk-component--signup-step-fullname'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((SignupStepFullnameDOM)=>{
            const component = createApp(SignupStepFullname,{
                currentStepTexts: {
                    title: SignupStepFullnameDOM.dataset.title,
                    summary: SignupStepFullnameDOM.dataset.summary,
                },
                stepCounterText: SignupStepFullnameDOM.dataset.stepcountertext
            }).use(Store)
            component.mount(SignupStepFullnameDOM)
        })
    }
}