import {createApp} from 'vue'
import AdjustLoanContent from './AdjustLoanContent'

const el = '.nyk-component--adjust-loan-content'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((AdjustLoanContentDOM)=>{
            const component = createApp(AdjustLoanContent).use(Store)
            component.mount(AdjustLoanContentDOM)
        })
    }
}