import { createApp } from "vue"
import FilterTabs from "./FilterTabs"

const el = ".nyk-component--filter-tabs"

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((FilterTabsDOM) => {
            const props = {
                initActiveTab: FilterTabsDOM.dataset.initActiveTab,
                tabs: JSON.parse(FilterTabsDOM.dataset.tabs),
                callback: FilterTabsDOM.dataset.callback,
            }
            const component = createApp(FilterTabs, props).use(Store)
            component.mount(FilterTabsDOM)
        })
    }
}
