import { createApp } from "vue"
import FeedbackStepThree from "./FeedbackStepThree"

const el = ".nyk-component--feedback-step-three"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((FeedbackStepThreeDOM) => {
      const props = {
        message: FeedbackStepThreeDOM.dataset.message ?? "",
      }
      const component = createApp(FeedbackStepThree, props).use(Store)
      component.mount(FeedbackStepThreeDOM)
    })
  }
}
