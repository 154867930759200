import {createApp} from 'vue'
import ContactStepComments from './ContactStepComments'

const el = '.nyk-component--contact-step-comments'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((ContactStepCommentsDOM)=>{
            const component = createApp(ContactStepComments).use(Store)
            component.mount(ContactStepCommentsDOM)
        })
    }
}