import { registerGtmDataLayer } from './gtmDataLayerService';

export default class GtmVideoService {
    Player = null;

    interval = null;
    watched = null;
    videoId = null;

    constructor(player, vidoeId) {
        this.Player = player;
        this.registredPercentages = {
            0 : false,
            25 : false,
            50 : false,
            75 : false,
            100 : false,
        };
        this.videoId = vidoeId;
        this.fallbackVideoTitle = "";

        // Add tracking on video player state
        this.Player.addEventListener("onStateChange", (state) => { 
            this.onVideoStateChanged(state); 
        });
    }
    
    onVideoStateChanged(state) {
        this.fallbackVideoTitle = state.target.videoTitle ?? this.fallbackVideoTitle
        if (state.data === YT.PlayerState.PLAYING) {
            clearInterval(this.interval);
            this.trackPossibleEvents();
            // track possible events every second 
            this.interval = setInterval(this.trackPossibleEvents, 1000);
        } else if (state.data === YT.PlayerState.ENDED) {
            clearInterval(this.interval);
            this.registerVideo(100);
        } else if (state.data === YT.PlayerState.PAUSED) {
            clearInterval(this.interval);
        }
    }

    trackPossibleEvents = () => {
        /**
         * The YouTube player API sets player.getCurrentTime 
         * not in the constructor but later (possibly when the video is loaded). 
         * Likely, this is a bug.
         * https://stackoverflow.com/questions/44523396/player-getduration-and-player-getcurrenttime-is-not-function-error
         * 
         * We wait until these are avilable to track any progress.
         */
        if (((typeof this.Player.getDuration) != "function") || ((typeof this.Player.getCurrentTime) != "function")) return;

        let duration = this.Player.getDuration();
        let played = this.Player.getCurrentTime();
        
        /**
         * getDuration() will return 0 until the video's metadata is loaded, 
         * which normally happens just after the video starts playing.
         * We do not record any information until we have a proper duration
         */
        if (duration == 0) return;

        let fractionPlayed = played / duration;

        // track least upper bound percentage
        let watchedPercentage = 0;
        let watchedArray = Object.keys(this.registredPercentages).reverse().filter( e => { if(e <= fractionPlayed * 100) return e; });
        if(watchedArray.length > 0) {
            watchedPercentage = watchedArray[0];
        }
        this.registerVideo(watchedPercentage);
    }

    registerVideo(watchedPercentage) {
        // do not continue if we already have registred this event
        if (this.registredPercentages[watchedPercentage]) return;

        this.registredPercentages[watchedPercentage] = true;

        let eventAction = watchedPercentage == 0 ? 'start' : `${watchedPercentage}%`;
        let videoId = this.videoId;

        /**
         * The title does not seem to be avilable in 
         * documentation for the API.
         * We therefore fallback to the url of the video instaed.
         */

        let eventLabel = this.Player.videoTitle ?? this.fallbackVideoTitle;
        
        /**
         * getVideoData is not documented in API, 
         * therefore we only override with the correct data if
         * this is avilable.
         */ 
        if ((typeof this.Player.getVideoData) === 'function') {
            var videoData = this.Player.getVideoData();
            eventLabel = videoData?.title || eventLabel || this.fallbackVideoTitle;
        }
        
        let dataLayer = {
            'eventCategory': 'video', 
            'eventAction': eventAction, 
            'eventLabel': eventLabel,
            'eventValue': 0, 
            'eventNoninteraction': true, 
            'videoId': videoId,
            'event': 'track-event',
        };

        registerGtmDataLayer(dataLayer);
    }

}