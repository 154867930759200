import notEmpty from './notEmptyInput'
/**
 * 
 * @param {string} emailInput - the complete email address 
 * @returns 
 */
export default function (emailInput) {
    const valueToValidate = emailInput
    const regexResult = valueToValidate ? valueToValidate.match(/^\S+@\S+\.[a-zA-Z]+$/g) : false
    const validationResult = !!(regexResult && regexResult.length)

    return notEmpty(valueToValidate) && validationResult
}