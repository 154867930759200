import { createApp } from "vue"
import FindCenterMapAndEmployees from "./FindCenterMapAndEmployees"

const el = ".nyk-component--find-center-map-and-employees"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((FindCenterMapAndEmployeesDOM) => {
      const props = {
        mapkey: FindCenterMapAndEmployeesDOM.dataset.mapkey,
        categories: JSON.parse(FindCenterMapAndEmployeesDOM.dataset.categories),
        labels: JSON.parse(FindCenterMapAndEmployeesDOM.dataset.labels),
        centersApiUrl: FindCenterMapAndEmployeesDOM.dataset.centersApiUrl,
        contactPersonsApiUrl: FindCenterMapAndEmployeesDOM.dataset.contactPersonsApiUrl,
        defaultEmployeesImage: FindCenterMapAndEmployeesDOM.dataset.defaultEmployeesImage,
        contactUrl: FindCenterMapAndEmployeesDOM.dataset.contactUrl ?? null,
      }
      const component = createApp(FindCenterMapAndEmployees, props).use(Store)
      component.mount(FindCenterMapAndEmployeesDOM)
    })
  }
}
