import FormRadioGroupInput from '../FormRadioGroupInput/FormRadioGroupInput.vue'
import FormGenericInput from '../FormGenericInput/FormGenericInput.vue'
import { computed, onMounted, onUpdated, ref } from 'vue'
import reverseThousandCommaSeparation from '../../js/reverseThousandCommaSeparation'
import applyThousandSeperator from '../../js/applyThousandSeperator'

export default {
    name: 'LoanCalculatorInputs',
    components: {
        FormRadioGroupInput,
        FormGenericInput,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        housingTypes: {
            type: Array,
            required: true,
        },
        initData: {
            type: Object,
            required: false,
        },
        onChangeCallback: {
            type: Function,
            required: true,
        },
        displayErrors: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const housePrice = ref(props.initData?.housePrice ?? '')
        const housePriceRaw = ref(props.initData?.housePrice ?? '')
        const ownPayment = ref(props.initData?.ownPayment ?? '')
        const ownPaymentRaw = ref(props.initData?.ownPayment ?? '')
        const housingType = ref('')
        const radioOptions = ref([])

        const housePriceErrormMessage = computed(() => {
            return getCurrentHouseTypeObject()?.housePriceErrorMessage.replace(
                '{minLoadAmount}',
                formatPrice(getCurrentHouseTypeObject()?.minLoadAmount)
            )
        })

        const ownPaymentErrorMessage = computed(() => {
            const minAmount = housePriceValidation()
                ? housePriceRaw.value * (getDownPaymentProcent() / 100)
                : getHousingTypeMinLoadAmount() * (getDownPaymentProcent() / 100)

            return getCurrentHouseTypeObject()
                ?.ownPaymentErrorMessage.replace('{downPaymentProcent}', getCurrentHouseTypeObject()?.downPaymentProcent)
                .replace('{minAmount}', formatPrice(minAmount))
        })

        onMounted(() => {
            props.housingTypes.forEach((item) => {
                radioOptions.value.push({
                    label: item.label,
                    id: item.keyName,
                    value: item.keyName,
                    checked: item.selected,
                })
                if (item.selected) {
                    housingType.value = item.keyName
                }
            })

            setOwnPayment(ownPayment.value)
            setHousePrice(housePrice.value)
        })

        onUpdated(() => {
            if (props.onChangeCallback) {
                props.onChangeCallback({
                    housePrice: housePriceRaw.value,
                    ownPayment: ownPaymentRaw.value,
                    housingType: housingType.value,
                    formValid: ownPaymentValidation() && housePriceValidation(),
                })
            }
        })

        const formatPrice = (price) => Math.round(price).toLocaleString('da-DK')

        const setOwnPayment = (value) => {
            ownPaymentRaw.value = reverseThousandCommaSeparation(value)
            ownPayment.value = applyThousandSeperator(reverseThousandCommaSeparation(value))
        }

        const setHousePrice = (value) => {
            housePriceRaw.value = reverseThousandCommaSeparation(value)
            housePrice.value = applyThousandSeperator(reverseThousandCommaSeparation(value))
        }

        const setHousingTypes = (e) => (housingType.value = e.target.value)

        const housePriceValidation = () => housePriceRaw.value >= getHousingTypeMinLoadAmount()

        const ownPaymentValidation = () => {
            // validate if own payment is big enough, and if house price is to low, then find to mininum own payment of the minimum house price
            return ownPaymentRaw.value > 0
                ? housePriceValidation()
                    ? ownPaymentRaw.value >= housePriceRaw.value * (getDownPaymentProcent() / 100)
                    : ownPaymentRaw.value >= getHousingTypeMinLoadAmount() * (getDownPaymentProcent() / 100)
                : false
        }

        const getDownPaymentProcent = () => getCurrentHouseTypeObject()?.downPaymentProcent
        const getHousingTypeMinLoadAmount = () => getCurrentHouseTypeObject()?.minLoadAmount
        const getCurrentHouseTypeObject = () => props.housingTypes.find((x) => x.keyName === housingType.value)

        return {
            housePrice,
            ownPayment,
            housingType,
            radioOptions,
            ownPaymentErrorMessage,
            housePriceErrormMessage,
            ownPaymentValidation,
            housePriceValidation,
            setOwnPayment,
            setHousePrice,
            setHousingTypes,
        }
    },
}
