export default function ({
    housePrice,
    ownPayment,
    housingType,
    mortgageLoanInterestOnlyLength,
    mortgageLoanTenureLength,
    bankLoanTenureLength,
    productType,
}) {
    const CollateralValue = `cv=${housePrice}`
    const DownPayment = `db=${ownPayment}`
    const CollateralType = `ct=${housingType}`
    const ProductType = `tp.${productType}`
    const MortgageLoanInterestOnlyLength = `~mliol.${mortgageLoanInterestOnlyLength}`
    const MortgageLoanTenureLength = `~mtl.${mortgageLoanTenureLength}`
    const BankLoanTenureLength = `~btl.${bankLoanTenureLength}`

    return `?${CollateralValue}&${DownPayment}&${CollateralType}&lon=${ProductType}${MortgageLoanInterestOnlyLength}${MortgageLoanTenureLength}${BankLoanTenureLength}`
}
