import { onMounted, ref } from "vue"
import _signupStepsCommonProps from "../../../../../Frontend/src/foundation/js/configurations/_signupStepsCommonProps"
import SignupStepActions from "../SignupStepActions/SignupStepActions.vue"
import Tooltip from "../../../../../Frontend/src/components/Tooltip/Tooltip.vue"

export default {
  name: "SignupStepSelectNewsletters",
  props: _signupStepsCommonProps,
  components: {
    SignupStepActions,
    Tooltip,
  },
  setup(props) {
    const newsletterFields = ref([])
    const displayError = ref(false)

    onMounted(() => {
      props.newslettersData.forEach((newsletter) => {
        newsletterFields.value.push({
            field: newsletter.displayname,
            salesforceMapping: newsletter.name,
          value: props.getNamedFieldInputData[newsletter.name],
          tooltip: newsletter.tooltip,
        })
      })
    })

    const handleNextStep = () => {
      const anySelected = newsletterFields.value.some((field) => field.value)

      if (anySelected) {
        const array = []

        newsletterFields.value.forEach((field) => {
          array.push({
              field: field.salesforceMapping,
              name: field.field,
            value: field.value,
          })
        })

        props.nextStepCallback(array)
      } else {
        displayError.value = true
      }
    }

    return {
      newsletterFields,
      handleNextStep,
      displayError,
    }
  },
}
