import { createApp } from 'vue'
import options from './Menu.options'

const el = '.nyk-component--menu'

export default function(Store) {
    const DOM = document.querySelectorAll(el)
    DOM.forEach((MenuDOM)=>{
        const props = {
            overlayId: MenuDOM.dataset.overlayId,
            searchUrl: MenuDOM.dataset.searchUrl
        }

        const template = MenuDOM.outerHTML
        options.template = template
        const component = createApp(options, props).use(Store)
        component.mount(MenuDOM)
    })
}

