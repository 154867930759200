import {createApp} from 'vue'
import SiteSearchingResult from './SiteSearchingResult'

const el = '.nyk-component--site-searching-result'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((SiteSearchingResultDOM)=>{
            const props = {
                tag: SiteSearchingResultDOM.dataset.tag,
                title: SiteSearchingResultDOM.dataset.title,
                description: SiteSearchingResultDOM.dataset.description,
                href: SiteSearchingResultDOM.dataset.href,
                filename: SiteSearchingResultDOM.dataset.filename
            }
            const component = createApp(SiteSearchingResult, props).use(Store)
            component.mount(SiteSearchingResultDOM)
        })
    }
}