import {createApp} from 'vue'
import SwitchLoanTypeContent from './SwitchLoanTypeContent'

const el = '.nyk-component--switch-loan-type-content'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((SwitchLoanTypeContentDOM)=>{
            const component = createApp(SwitchLoanTypeContent).use(Store)
            component.mount(SwitchLoanTypeContentDOM)
        })
    }
}