

export default {
    namespaced: true,
    state: {
        test: true,
    },
    mutations: {
        set_test(state,value){
            state.test = value
        }
    },
    actions: {
        testAction(context, config) {
            context.commit('set_test', config.test)
        }
    }
}