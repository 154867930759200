import {createApp} from 'vue'
import options from './StickyBox.options'

const el = '.nyk-component--sticky-box'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    DOM.forEach((StickyBoxDOM)=>{
        const props = {
            targetEndId: StickyBoxDOM.dataset.targetEndId
        }
        const template = StickyBoxDOM.innerHTML
        options.template = template
        const component = createApp(options, props).use(Store)
        component.mount(StickyBoxDOM)
    })
}

