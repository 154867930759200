export const getTableHeaders = (data) => {
    const header = []

    if (data.value) return header

    if (data.tooltip) {
        // tooltip header
        header.push({
            name: `<h3>${data.title}</h3>`,
            scope: 'row',
            tooltip: data.tooltip,
            tooltipTitle: data.title,
            styles: 'tooltip',
        })
    } else {
        header.push({ name: `<h3>${data.title}</h3>`, scope: 'row' })
    }

    return header
}

export const getTableRows = (data) => {
    const rows = []

    if (data.value) {
        rows.push({
            row: [{ name: data.title }, { name: data.value, styles: 'text-right' }],
        })
    }

    data.values.forEach((item) => {
        rows.push({
            hide: item.hide,
            row: [
                { name: item.key },
                {
                    name: item.value,
                    styles: 'text-right',
                },
            ],
        })
    })

    return rows
}
