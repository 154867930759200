import { onMounted, ref } from "vue";

export default {
    name: "ContextBanner",
    props: {
        bannerId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const bannerHidden = ref(false)
        const COOKIE_VALUE = "HIDDEN"

        const getCookie = (cookieName) => {
            return document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${cookieName}=`))
            ?.split("=")[1];
        }

        const setCookie = (name, value, exdays) => {
            const date = new Date();
            date.setTime(date.getTime() + (exdays*24*60*60*1000));
            let expires = "expires="+ date.toUTCString();
            
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        }

        const checkCookie = () => {
            bannerHidden.value = getCookie(props.bannerId) === COOKIE_VALUE    
        }

        onMounted(() => {
            checkCookie()
        })

        const removeBanner = () => {   
            setCookie(props.bannerId, COOKIE_VALUE, 30)
            checkCookie()
        }

        return {
            removeBanner,
            bannerHidden
        }
    }
}