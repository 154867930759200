<script>
import options from './MultiSelectDropdown.options'
export default options
</script>

<template>
    <article
        class="nyk-multi-select-dropdown nyk-js--multi-select-dropdown"
        :class="{ 'nyk-multi-select-dropdown--popup-open': popupOpen }">
        <button
            ref="dropdownButtonRef"
            v-on:click="toggleDropdown"
            class="nyk-multi-select-dropdown__button"
            :id="`${labelId}-combo`"
            :aria-controls="`${labelId}-listbox`"
            aria-haspopup="listbox"
            :aria-expanded="popupOpen"
            :aria-labelledby="labelId">
            <div class="nyk-multi-select-dropdown__button__label" :id="labelId">{{ title }}</div>
            <div class="nyk-multi-select-dropdown__button__selected">
                {{ selectedValues }}
            </div>

            <button
                v-if="!showArrow"
                class="nyk-multi-select-dropdown__button__close"
                :class="{
                    'nyk-multi-select-dropdown__button__close--show': optionsIsNotEmpty,
                }"
                v-on:click.stop="emptySelectedResults">
                <span class="nyk-icon nyk-icon--ui-close"></span>
            </button>

            <span class="nyk-multi-select-dropdown__button__arrow" v-if="showArrow"></span>
        </button>

        <div
            class="nyk-multi-select-dropdown__popup"
            ref="dropdownListRef"
            role="combobox"
            :id="`${labelId}-listbox`"
            :aria-labelledby="labelId"
            tabindex="-1">
            <form class="nyk-multi-select-dropdown__popup__form" v-on:change="onChangeDropdown">
                <template v-for="(option, index) in options" :key="index">
                    <div class="nyk-form nyk-js--form nyk-form-choice nyk-multi-select-dropdown__popup__form__item">
                        <div class="nyk-form-choice__content">
                            <div class="nyk-form-choice__content__item nyk-js--choice-wrapper">
                                <div class="nyk-form-choice__content__item__choice">
                                    <input
                                        class="nyk-form-choice__content__item__choice__input"
                                        :id="option.value"
                                        :data-name="option.name"
                                        type="checkbox"
                                        :value="option.value"
                                        :checked="option.selected"
                                        :name="title"
                                        v-on:keydown="handleKeyTab"
                                        role="option"
                                        :aria-selected="option.selected" />
                                    <div class="nyk-form-choice__content__item__choice__square">
                                        <div class="nyk-form-choice__content__item__choice__square__outer"></div>
                                        <div class="nyk-form-choice__content__item__choice__square__inner"></div>
                                    </div>
                                </div>
                                <label class="nyk-form-choice__content__item__label" :for="option.value">{{ option.name }}</label>
                            </div>
                        </div>
                    </div>
                </template>
            </form>
        </div>
    </article>
</template>
