import { createApp } from 'vue'
import LoanCalculatorInputs from './NewLoanCalculatorInputs'
import handleComponentProps from '../../../../../../../Frontend/src/foundation/js/utils/handleComponentProps.js'

const el = '.nyk-component--loan-calculator-inputs'

export default function (Store) {
    const DOM = document.querySelectorAll(el)
    if (DOM.length) {
        DOM.forEach((LoanCalculatorInputsDOM) => {
            const props = handleComponentProps(LoanCalculatorInputs, LoanCalculatorInputsDOM)
            const component = createApp(LoanCalculatorInputs, props).use(Store)
            component.mount(LoanCalculatorInputsDOM)
        })
    }
}
