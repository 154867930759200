export default function (inputData, userInput, stepsConfigAndOrder, currentStepName, callback) {
  let displayInputError = false

  const advanceToNextStep = function () {
    stepsConfigAndOrder.find((stepData) => stepData.name === currentStepName).completed = true
    displayInputError = false
    const allStepInputComplete = userInput.every((stepField) => stepField.isValid)

    if (allStepInputComplete) {
      callback(userInput)
    }
  }

  inputData.forEach((input) => {
    const inputDatafield = userInput.find((field) => field.field === input.field)
    inputDatafield.isValid = inputDatafield.validator(input.value)

    // we are validating fields one by one
    if (inputDatafield.isValid) {
      inputDatafield.value = input.value
    } else {
      displayInputError = true
    }
  })

  const stepInputComplete = userInput
    .filter((dataField) => {
      return inputData.some((inputfield) => inputfield.field === dataField.field)
    })
    .every((stepField) => stepField.isValid)

  if (stepInputComplete) {
    advanceToNextStep()
  }

  return displayInputError
}
