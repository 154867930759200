export default {
    name: 'CalculatorInfoBox',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup(props) {},
}
